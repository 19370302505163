/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import styled from "style/styled";

import {
  useReducer,
  useState,
  useCallback,
  useEffect,
  Fragment,
  Dispatch,
} from "react";

import { produce } from "immer";

import $ from "jquery";

import { useDropzone } from "react-dropzone";

import stringSimilarity from "string-similarity";

import Airtable from "airtable";

import { Trash2 } from "react-feather";

import { useAirtableApiStatus } from "components/utils/airtable_api_provider";

import Loading from "components/utils/loading";

import DynamicMarginContainer from "components/utils/container/dynamic_margin";
import Settings from "components/settings";
import TableSelect from "components/utils/airtable/table_select";
import Button from "components/utils/button";
import { AddressSelectView } from "components/utils/airtable/address_select";

interface AddressQuery extends Airtable.FieldSet {
  Address: string;
}

const Parse = () => {
  const th = useTheme<Theme>();

  const [state, dispatch] = useReducer(reducer, initialState);

  const [isPushing, setIsPushing] = useState(false);
  const [hasDropError, setHasDropError] = useState(false);
  const [hasPushError, setHasPushError] = useState(false);
  const [numRecordsUpdated, setNumRecordsUpdated] = useState<
    [number, number] | null
  >(null);

  const airtableApiStatus = useAirtableApiStatus();
  const apiKey = airtableApiStatus.key;
  const apiBase = airtableApiStatus.base;

  const handleTableRequest = useCallback(
    (resp: Response) => {
      resp.json().then((i) => {
        if (resp.status === 200) {
          const options: Option[] = i.tables.reduce((tot: Option[], i: any) => {
            const requiredFields = [
              "Address",
              "Cap (PF Cap)",
              "Price",
              "GLA",
              "Lot",
              "Parking Ratio (Stalls)",
              "Ownership Notes",
            ];
            const hasRequiredFields = requiredFields.every(
              (j) => i.fields.find((ii: any) => ii["name"] === j) !== undefined
            );
            if (hasRequiredFields) {
              tot.push({ id: i.id, label: i.name });
            }
            return tot;
          }, [] as Option[]);
          dispatch({
            type: "updateTableOptions",
            val: options,
          });
        }
      });
    },
    [dispatch]
  );

  const handleTableSelection = useCallback(
    (id: string) => {
      dispatch({ type: "selectTable", id: id });
    },
    [dispatch]
  );

  const handleRefreshTableData = useCallback(() => {
    dispatch({ type: "refreshTableData" });
  }, [dispatch]);

  useEffect(() => {
    if (state.tables?.selection) {
      if (apiKey && apiBase) {
        const base = new Airtable({ apiKey: apiKey }).base(apiBase);
        const baseTable = base(state.tables.selection.label) as Airtable.Table<
          AddressQuery
        >;
        let allRecords: Array<Option> = [];
        baseTable
          .select({
            view: "Grid view",
            fields: ["Address"],
          })
          .eachPage((records, next) => {
            allRecords = allRecords.concat(
              records
                .filter((i) => i.fields.Address)
                .map((i) => ({
                  id: i.id,
                  label: i.fields.Address,
                }))
            );
            next();
          })
          .then(() => {
            dispatch({ type: "updateAddressOptions", val: allRecords });
          });
      } else {
        throw `airtable api key and api base must be set to retrieve table addresses`;
      }
    }
  }, [state.tables, apiKey, apiBase]);

  useCallback(
    (base: Airtable.Base, table: string) => {
      const baseTable = base(table) as Airtable.Table<AddressQuery>;
      let allRecords: Array<Option> = [];
      baseTable
        .select({
          view: "Grid view",
          fields: ["Address"],
        })
        .eachPage((records, next) => {
          allRecords = allRecords.concat(
            records
              .filter((i) => i.fields.Address)
              .map((i) => ({
                id: i.id,
                label: i.fields.Address,
              }))
          );
          next();
        })
        .then(() => {
          dispatch({ type: "updateAddressOptions", val: allRecords });
        });
    },
    [dispatch]
  );

  const readFile = (f: any, idx: number) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onabort = () => {
        reject(new Error("file reading was aborted"));
      };
      reader.onerror = () => {
        reject(new Error("file reading has failed"));
      };
      reader.onload = () => {
        const binaryStr = reader.result as string;
        let dom = $("<temp>").append($.parseHTML(binaryStr));
        let pd: PageData = {
          filepath: f.path,
          fileaddress: getfileaddress(f.name),
          address: getaddress(dom),
          caprate: getcaprate(dom),
          saleprice: getsaleprice(dom),
          buildingarea: getbuildingarea(dom),
          landgrosssf: getlandgrosssf(dom),
          landnetsf: getlandnetsf(dom),
          landgrossac: getlandgrossac(dom),
          landnetac: getlandnetac(dom),
          parking: getparking(dom),
          trueowner: gettrueowner(dom),
          recordedowner: getrecordedowner(dom),
        };
        const addr = pd.fileaddress || pd.address;
        let selection: Option | null = null;
        if (state.addressOptions && addr) {
          const matches = stringSimilarity.findBestMatch(
            addr,
            state.addressOptions.map((i) => i.label)
          );
          selection = state.addressOptions[matches.bestMatchIndex] || null;
        }
        dispatch({
          type: "commit",
          key: idx.toString(),
          data: pd,
          selection: selection,
          isFirst: idx === 0,
        });
        resolve("ok");
      };
      reader.readAsText(f);
    });
  };

  const onDrop = useCallback(
    (i) => {
      setHasDropError(false);
      setHasPushError(false);
      setNumRecordsUpdated(null);
      const allReads: Array<Promise<any>> = i.map(readFile);
      Promise.all(allReads).catch(() => {
        setHasDropError(true);
      });
    },
    [state]
  );

  // older version without promises
  // const onDrop = useCallback(
  //   (i) => {
  //     setHasPushError(false);
  //     setNumRecordsUpdated(null);
  //     i.forEach((j: any, idx: number) => {
  //       const reader = new FileReader();
  //       reader.onabort = () => console.log("file reading was aborted");
  //       reader.onerror = () => console.log("file reading has failed");
  //       reader.onload = () => {
  //         const binaryStr = reader.result as string;
  //         let dom = $("<temp>").append($.parseHTML(binaryStr));
  //         let pd: PageData = {
  //           filepath: j.path,
  //           fileaddress: getfileaddress(j.name),
  //           address: getaddress(dom),
  //           caprate: getcaprate(dom),
  //           saleprice: getsaleprice(dom),
  //           buildingarea: getbuildingarea(dom),
  //           landgrosssf: getlandgrosssf(dom),
  //           landnetsf: getlandnetsf(dom),
  //           landgrossac: getlandgrossac(dom),
  //           landnetac: getlandnetac(dom),
  //           parking: getparking(dom),
  //           trueowner: gettrueowner(dom),
  //           recordedowner: getrecordedowner(dom),
  //         };
  //         const addr = pd.fileaddress || pd.address;
  //         let selection: Option | null = null;
  //         if (state.addressOptions && addr) {
  //           const matches = stringSimilarity.findBestMatch(
  //             addr,
  //             state.addressOptions.map((i) => i.label)
  //           );
  //           selection = state.addressOptions[matches.bestMatchIndex] || null;
  //         }
  //         dispatch({
  //           type: "commit",
  //           key: idx.toString(),
  //           data: pd,
  //           selection: selection,
  //           isFirst: idx === 0,
  //         });
  //       };
  //       reader.readAsText(j);
  //     });
  //   },
  //   [state]
  // );

  const handlePush = () => {
    if (apiKey && apiBase && state.tables?.selection && state.pages) {
      setHasPushError(false);
      setNumRecordsUpdated(null);
      setIsPushing(true);
      Object.values(state.pages).every((i) => i.selection?.id !== undefined);

      const base = new Airtable({ apiKey: apiKey }).base(apiBase);
      const baseTable = base(state.tables.selection.id);
      const update = Object.values(state.pages).map((i) => {
        if (i.selection === null) {
          throw `all pages must have a selected address`;
        } else {
          return {
            id: i.selection.id,
            fields: Object.values(i.dataOut).reduce((tot, v) => {
              if (v) {
                tot[v.field] = v.val;
              }
              return tot;
            }, {} as any),
          };
        }
      });
      baseTable
        .update(update)
        .then((i) => {
          setNumRecordsUpdated([i.length, update.length]);
          setIsPushing(false);
        })
        .catch((e) => {
          console.log("PUSH ERROR:", e);
          setIsPushing(false);
          setHasPushError(true);
        });
    }
  };

  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginBottom: th.space[12],
      }}
    >
      <Settings />
      {apiKey && apiBase && (
        <div
          css={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            css={{
              backgroundColor: th.semanticColors.accents[0],
              paddingTop: th.space[8],
              paddingBottom: th.space[10],
              borderBottomStyle: "solid",
              borderBottomWidth: th.borderWidths[1],
              borderColor: th.semanticColors.accents[2],
            }}
          >
            <DynamicMarginContainer>
              <p
                css={{
                  ...th.modules.text.body,
                  fontSize: th.fontSizes.larger[0],
                  fontWeight: th.fontWeights.medium,
                  marginRight: th.space[8],
                }}
              >
                {"Parse"}
              </p>
              <TableSelect
                options={state.tables?.options || null}
                selection={state.tables?.selection || null}
                handleTableRequest={handleTableRequest}
                handleTableSelection={handleTableSelection}
                handleRefreshTableData={handleRefreshTableData}
                apiKey={apiKey}
                apiBase={apiBase}
              />
            </DynamicMarginContainer>
          </div>
          {state.tables?.selection && (
            <div
              css={{
                backgroundColor: th.semanticColors.accents[1],
                paddingTop: th.space[8],
                paddingBottom: th.space[10],
                borderBottomStyle: "solid",
                borderBottomWidth: th.borderWidths[1],
                borderColor: th.semanticColors.accents[2],
              }}
            >
              <DynamicMarginContainer>
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {state.addressOptions ? (
                    <Fragment>
                      <p
                        css={{
                          marginBottom: th.space[8],
                          fontWeight: th.fontWeights.semibold,
                        }}
                      >
                        Drop Files
                      </p>
                      <div
                        {...getRootProps()}
                        css={{
                          height: th.sizes[8],
                          borderRadius: th.radii[2],
                          borderWidth: th.borderWidths[3],
                          borderStyle: "dashed",
                          borderColor: th.semanticColors.accents[5],
                          backgroundColor: isDragActive
                            ? th.semanticColors.accents[0]
                            : th.semanticColors.accents[1],
                        }}
                      />
                      {hasDropError && (
                        <div>
                          <p
                            css={{
                              marginTop: th.space[6],
                              fontWeight: th.fontWeights.medium,
                              color: th.semanticColors.error.base,
                            }}
                          >
                            {"Not all dropped files read"}
                          </p>
                        </div>
                      )}
                      {state.pages && state.addressOptions && (
                        <div
                          css={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            css={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {Object.entries(state.pages).map(([k, v]) => (
                              <ParseEntry
                                options={state.addressOptions! as Array<Option>}
                                selection={v.selection}
                                data={v.data}
                                dataOut={v.dataOut}
                                id={k}
                                dispatch={dispatch}
                                key={JSON.stringify(v.data)}
                              />
                            ))}
                          </div>
                          <div
                            css={{
                              display: "flex",
                              alignItems: "baseline",
                              marginTop: th.space[10],
                            }}
                          >
                            <div
                              css={{
                                width: th.sizes[10],
                              }}
                            >
                              <Button
                                onClickHandler={handlePush}
                                label={"Push To DB"}
                                disabled={Object.values(state.pages).some(
                                  (i) => i.selection?.id === undefined
                                )}
                              />
                            </div>
                            {isPushing && (
                              <div
                                css={{
                                  alignSelf: "center",
                                  marginLeft: th.space[10],
                                  maxWidth: th.sizes[8],
                                }}
                              >
                                <Loading label={"pushing results..."} />
                              </div>
                            )}
                            {numRecordsUpdated && (
                              <p
                                css={{
                                  marginLeft: th.space[10],
                                  fontWeight: th.fontWeights.medium,
                                  fontSize: th.fontSizes.smaller[1],
                                  ...(numRecordsUpdated[0] !==
                                  numRecordsUpdated[1]
                                    ? { color: th.semanticColors.error.base }
                                    : {
                                        color: th.semanticColors.success.base,
                                      }),
                                }}
                              >
                                {`${numRecordsUpdated[0]}/${numRecordsUpdated[1]} Records Pushed`}
                              </p>
                            )}
                            {hasPushError && (
                              <p
                                css={{
                                  marginLeft: th.space[10],
                                  fontWeight: th.fontWeights.medium,
                                  fontSize: th.fontSizes.smaller[1],
                                  color: th.semanticColors.error.base,
                                }}
                              >
                                {
                                  "error pushing data... check console for details"
                                }
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </Fragment>
                  ) : (
                    <Loading label={"addresses loading..."} />
                  )}
                </div>
              </DynamicMarginContainer>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Parse;

const ParseEntry = ({
  options,
  selection,
  data,
  dataOut,
  id,
  dispatch,
}: {
  options: Array<Option>;
  selection: Option | null;
  data: PageData;
  dataOut: PageDataOut;
  id: string;
  dispatch: Dispatch<Action>;
}) => {
  const th = useTheme<Theme>();

  const handleAddressSelection = useCallback(
    (addressId: string) => {
      dispatch({
        type: "selectAddress",
        id: addressId,
        pageId: id,
      });
    },
    [dispatch, id]
  );

  const handleAddressChange = useCallback(() => {
    dispatch({
      type: "unselectAddress",
      pageId: id,
    });
  }, [dispatch, id]);

  return (
    <div
      css={{
        marginTop: th.space[10],
        paddingBottom: th.space[10],
        borderRadius: th.radii[2],
        borderWidth: th.borderWidths[3],
        borderStyle: "solid",
        borderColor: th.semanticColors.accents[5],
        backgroundColor: th.semanticColors.accents[0],
      }}
    >
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: th.space[9],
          paddingBottom: th.space[9],
          paddingLeft: th.space[10],
          paddingRight: th.space[10],
          borderBottomStyle: "solid",
          borderWidth: th.borderWidths[2],
          borderColor: th.semanticColors.accents[5],
          backgroundColor: "#FFF",
        }}
      >
        <p>{data.filepath}</p>
        <div
          onClick={() => dispatch({ type: "deletePage", pageId: id })}
          css={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            color: th.semanticColors.accents[5],
            ":hover": {
              color: th.semanticColors.foreground,
            },
          }}
        >
          <Trash2 size={th.fontSizes.larger[0]} />
        </div>
      </div>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(min-content, max-content))",
          gridColumnGap: th.space[10],
          gridRowGap: th.space[4],
          fontSize: th.fontSizes.smaller[0],
          marginTop: th.space[11],
          paddingLeft: th.space[11],
          paddingRight: th.space[10],
        }}
      >
        <TitleP>{"Corrected Address"}</TitleP>
        <ItemP>{data.fileaddress}</ItemP>
        <TitleP>{"Page Address"}</TitleP>
        <ItemP>{data.address}</ItemP>
        <TitleP>{"Cap Rate"}</TitleP>
        <ItemP>{data.caprate}</ItemP>
        <TitleP>{"Sale Price"}</TitleP>
        <ItemP>{data.saleprice}</ItemP>
        <TitleP>{`Building Area`}</TitleP>
        <ItemP>
          {data.buildingarea
            ? `${data.buildingarea.val} ${data.buildingarea.type}`
            : ``}
        </ItemP>
        <TitleP>{"Land Area - Gross SF"}</TitleP>
        <ItemP>{data.landgrosssf}</ItemP>
        <TitleP>{"Land Area - Net SF"}</TitleP>
        <ItemP>{data.landnetsf}</ItemP>
        <TitleP>{"Land Area - Gross AC"}</TitleP>
        <ItemP>{data.landgrossac}</ItemP>
        <TitleP>{"Land Area - Net AC"}</TitleP>
        <ItemP>{data.landnetac}</ItemP>
        <TitleP>{"Parking"}</TitleP>
        <ItemP>{data.parking}</ItemP>
        <TitleP>{"Recorded Owner"}</TitleP>
        <ItemP>{data.recordedowner}</ItemP>
        <TitleP>{"True Owner"}</TitleP>
        <ItemP>{data.trueowner}</ItemP>
        {Object.keys(dataOut).length > 0 && (
          <Fragment>
            <AltTitleP>{""}</AltTitleP>
            <p>{""}</p>
            <AltTitleP>{""}</AltTitleP>
            <p>{""}</p>
            {dataOut.caprate && (
              <Fragment>
                <AltTitleP>{dataOut.caprate.field}</AltTitleP>
                <AltItemP>{dataOut.caprate.val}</AltItemP>
              </Fragment>
            )}
            {dataOut.price && (
              <Fragment>
                <AltTitleP>{dataOut.price.field}</AltTitleP>
                <AltItemP>{dataOut.price.val}</AltItemP>
              </Fragment>
            )}
            {dataOut.gla && (
              <Fragment>
                <AltTitleP>{dataOut.gla.field}</AltTitleP>
                <AltItemP>{dataOut.gla.val}</AltItemP>
              </Fragment>
            )}
            {dataOut.lot && (
              <Fragment>
                <AltTitleP>{dataOut.lot.field}</AltTitleP>
                <AltItemP>{dataOut.lot.val}</AltItemP>
              </Fragment>
            )}
            {dataOut.parking && (
              <Fragment>
                <AltTitleP>{dataOut.parking.field}</AltTitleP>
                <AltItemP>{dataOut.parking.val}</AltItemP>
              </Fragment>
            )}
            {dataOut.owner && (
              <Fragment>
                <AltTitleP>{dataOut.owner.field}</AltTitleP>
                <AltItemP>{dataOut.owner.val}</AltItemP>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
      <div
        css={{
          marginTop: th.space[11],
          paddingLeft: th.space[10],
          paddingRight: th.space[10],
          maxWidth: th.sizes[13],
        }}
      >
        <AddressSelectView
          options={options}
          handleAddressSelection={handleAddressSelection}
          handleAddressChange={handleAddressChange}
          initSelection={selection || undefined}
        />
      </div>
    </div>
  );
};

const TitleP = styled.p`
  font-weight: ${(p) => p.theme.fontWeights.medium};
  text-align: right;
  /* color: ${(p) => p.theme.semanticColors.accents[6]}; */
`;

const ItemP = styled.p`
  /* color: ${(p) => p.theme.semanticColors.accents[6]}; */
`;

const AltTitleP = styled.p`
  font-weight: ${(p) => p.theme.fontWeights.medium};
  text-align: right;
  color: ${(p) => p.theme.semanticColors.accents[5]};
`;

const AltItemP = styled.p`
  color: ${(p) => p.theme.semanticColors.accents[5]};
`;

const getfileaddress = (a: string) => {
  if (a.endsWith("-baph.html")) {
    let out = a.replace("-baph.html", "");
    out = out.replace(/_/g, " ");
    return out;
  }
  return null;
};

const getaddress = (dom: JQuery<HTMLElement>) => {
  const parent1 = $(`[class*="detail-header__address-header--"]`, dom);
  if (parent1.length === 0) {
    return null;
  }
  const node1 = parent1[parent1.length - 1];
  const parent2 = $(`[class*="detail-header__address-line3--"]`, dom);
  if (parent2.length === 0) {
    return null;
  }
  const node2 = parent2[parent2.length - 1];
  return `${node1.textContent}, ${node2.textContent}`;
};

const getcaprate = (dom: JQuery<HTMLElement>) => {
  const forSaleParent = $(
    `#ForSaleSummary_CapitalizationRate_Display span`,
    dom
  );
  if (forSaleParent.length > 0) {
    const node = forSaleParent[forSaleParent.length - 1];
    if (node.textContent && node.textContent.length > 0) {
      return node.textContent;
    }
  }

  const recentSaleParent = $(
    `#RecentSaleCompSummary_CapitalizationRate_Display span`,
    dom
  );
  if (recentSaleParent.length > 0) {
    const node = recentSaleParent[recentSaleParent.length - 1];
    if (node.textContent && node.textContent.length > 0) {
      return node.textContent;
    }
  }

  return null;
};

const getsaleprice = (dom: JQuery<HTMLElement>) => {
  const parent = $(`#ForSaleSummary_ForSaleDescription span`, dom);
  if (parent.length === 0) {
    return null;
  }
  const node = parent[parent.length - 1];
  return node.textContent;
};

const getbuildingarea = (dom: JQuery<HTMLElement>) => {
  const parent = $(`#Building_BuildingArea_Display span`, dom);
  if (parent.length < 2) {
    return null;
  }
  const node1 = parent[parent.length - 2];
  const node2 = parent[parent.length - 1];
  return {
    type: node1.textContent!,
    val: node2.textContent!,
  };
};

const getlandgrosssf = (dom: JQuery<HTMLElement>) => {
  const parent = $(`#Land_HighPrecisionGrossArea_Display span`, dom);
  const node = parent[parent.length - 1];
  return node.textContent;
};

const getlandnetsf = (dom: JQuery<HTMLElement>) => {
  const parent = $(`#Land_HighPrecisionNetArea_Display span`, dom);
  if (parent.length === 0) {
    return null;
  }
  const node = parent[parent.length - 1];
  return node.textContent;
};

const getlandgrossac = (dom: JQuery<HTMLElement>) => {
  const parent = $(`#Land_LowPrecisionGrossArea_Display span`, dom);
  if (parent.length === 0) {
    return null;
  }
  const node = parent[parent.length - 1];
  return node.textContent;
};

const getlandnetac = (dom: JQuery<HTMLElement>) => {
  const parent = $(`#Land_LowPrecisionNetArea_Display span`, dom);
  if (parent.length === 0) {
    return null;
  }
  const node = parent[parent.length - 1];
  return node.textContent;
};

const getparking = (dom: JQuery<HTMLElement>) => {
  const parent = $(`#Building_ParkingDescription span`, dom);
  if (parent.length === 0) {
    return null;
  }
  const node = parent[parent.length - 1];
  return node.textContent;
};

const gettrueowner = (dom: JQuery<HTMLElement>) => {
  const parent = $(`#TrueOwner_Name span`, dom);
  if (parent.length === 0) {
    return null;
  }
  const node = parent[parent.length - 1];
  return node.textContent;
};

const getrecordedowner = (dom: JQuery<HTMLElement>) => {
  const parent = $(`#RecordedOwner_Name span`, dom);
  if (parent.length === 0) {
    return null;
  }
  const node = parent[parent.length - 1];
  return node.textContent;
};

const getPageDataOutput = (x: PageData): PageDataOut => {
  let out: PageDataOut = {};
  const numPattern = `[0-9]{1,3}(,[0-9]{3})*(.[0-9]+)?`;

  const extractFirstNumber = (x: string | null, asInt: boolean) => {
    if (x !== null) {
      const numRe = new RegExp(numPattern, "g");
      const m = x.match(numRe);
      if (m !== null) {
        const mClean = m[0].replace(/,/g, "");
        if (asInt) {
          const out = parseInt(mClean);
          if (Number.isInteger(out)) {
            return out;
          }
        } else {
          const out = parseFloat(mClean);
          if (!isNaN(out)) {
            return out;
          }
        }
      }
    }
    return null;
  };

  const extractFirstNumberInt = (x: string | null) =>
    extractFirstNumber(x, true);
  const extractFirstNumberFloat = (x: string | null) =>
    extractFirstNumber(x, false);

  const extractFirstRatio = (x: string | null) => {
    if (x !== null) {
      const numRatioRe = new RegExp(`${numPattern}/${numPattern}`, "g");
      const m = x.match(numRatioRe);
      if (m !== null) {
        return m[0];
      }
    }
    return null;
  };

  if (x.caprate) {
    out["caprate"] = {
      field: "Cap (PF Cap)",
      val: x.caprate,
    };
  }

  if (x.saleprice) {
    const p = extractFirstNumberFloat(x.saleprice);
    if (p !== null) {
      out["price"] = {
        field: "Price",
        val: p,
      };
    }
  }

  if (x.buildingarea) {
    const a = extractFirstNumberFloat(x.buildingarea.val);
    if (a !== null) {
      out["gla"] = {
        field: "GLA",
        val: a,
      };
    }
  }

  const lgsf = extractFirstNumberFloat(x.landgrosssf);
  const lgac = extractFirstNumberFloat(x.landgrossac);
  const lnsf = extractFirstNumberFloat(x.landnetsf);
  const lnac = extractFirstNumberFloat(x.landnetac);
  let lotVal: number | null = null;

  if (lgsf || lgac || lnsf || lnac) {
    if (lgsf) {
      if (lgsf <= 87120) {
        lotVal = lgsf;
      } else if (!lgac) {
        lotVal = lgsf;
      } else {
        lotVal = lgac;
      }
    } else if (lgac) {
      lotVal = lgac;
    } else if (lnsf) {
      if (lnsf <= 87120) {
        lotVal = lnsf;
      } else if (!lnac) {
        lotVal = lnsf;
      } else {
        lotVal = lnac;
      }
    } else {
      lotVal = lnac!;
    }
  }

  if (lotVal) {
    out["lot"] = {
      field: "Lot",
      val: lotVal,
    };
  }

  if (x.parking) {
    const r = extractFirstRatio(x.parking);
    const psearch = r ? x.parking.replace(new RegExp(r, "g"), "") : x.parking;
    const p = extractFirstNumberFloat(psearch);
    if (r || p) {
      out["parking"] = {
        field: "Parking Ratio (Stalls)",
        val: `${r !== null && `${r.replace("1,000", "1k")} `}${p && `(${p})`}`,
      };
    }
  }

  if (x.trueowner || x.recordedowner) {
    out["owner"] = {
      field: "Ownership Notes",
      val: `${x.trueowner && `True Owner **${x.trueowner}**`}${
        x.trueowner && x.recordedowner && `\n`
      }${x.recordedowner && `Recorded Owner **${x.recordedowner}**`}`,
    };
  }

  return out;
};

export type Option = { id: string; label: string };

type PageData = {
  filepath: string;
  fileaddress: string | null;
  address: string | null;
  caprate: string | null;
  saleprice: string | null;
  buildingarea: {
    type: string;
    val: string;
  } | null;
  landgrosssf: string | null;
  landnetsf: string | null;
  landgrossac: string | null;
  landnetac: string | null;
  parking: string | null;
  recordedowner: string | null;
  trueowner: string | null;
};

interface PageDataOutVal<T> {
  field: string;
  val: T;
}

type PageDataOut = {
  caprate?: PageDataOutVal<string>;
  price?: PageDataOutVal<number>;
  gla?: PageDataOutVal<number>;
  lot?: PageDataOutVal<number>;
  parking?: PageDataOutVal<string>;
  owner?: PageDataOutVal<string>;
};

type PageStateValue = {
  data: PageData;
  dataOut: PageDataOut;
  selection: Option | null;
};

type PageState = {
  [key: string]: PageStateValue;
};

type State = {
  tables: {
    options: Array<Option>;
    selection: Option | null;
    selectionRefreshCount: number;
  } | null;
  addressOptions: Array<Option> | null;
  pages: PageState | null;
};

type Action =
  | {
      type: "updateTableOptions";
      val: Array<Option>;
    }
  | {
      type: "selectTable";
      id: string;
    }
  | {
      type: "refreshTableData";
    }
  | {
      type: "updateAddressOptions";
      val: Array<Option>;
    }
  | {
      type: "selectAddress";
      id: string;
      pageId: string;
    }
  | {
      type: "unselectAddress";
      pageId: string;
    }
  | {
      type: "deletePage";
      pageId: string;
    }
  | {
      type: "commit";
      key: string;
      data: PageData;
      selection: Option | null;
      isFirst: boolean;
    };

const initialState = {
  tables: null,
  addressOptions: null,
  pages: null,
};

const reducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case "updateTableOptions":
      draft.tables = {
        options: action.val,
        selection: null,
        selectionRefreshCount: 0,
      };
      draft.addressOptions = null;
      draft.pages = null;
      break;
    case "selectTable":
      if (draft.tables !== null) {
        const optionSelection = draft.tables.options.find(
          (i) => i.id === action.id
        );
        if (optionSelection === undefined) {
          throw `id ${action.id} not found in table options`;
        } else {
          draft.tables.selection = optionSelection;
          draft.tables.selectionRefreshCount = 0;
          draft.addressOptions = null;
          draft.pages = null;
        }
      } else {
        throw `table options must come before selection`;
      }
      break;
    case "refreshTableData":
      if (draft.tables !== null) {
        draft.tables.selectionRefreshCount++;
        draft.addressOptions = null;
        draft.pages = null;
      } else {
        throw `table options must come before selection`;
      }
      break;
    case "updateAddressOptions":
      draft.addressOptions = action.val;
      draft.pages = null;
      break;
    case "selectAddress":
      if (draft.addressOptions !== null) {
        const optionSelection = draft.addressOptions.find(
          (i) => i.id === action.id
        );
        if (optionSelection === undefined) {
          throw `id ${action.id} not found in address options`;
        } else {
          if (draft.pages !== null) {
            if (draft.pages[action.pageId]) {
              draft.pages[action.pageId].selection = optionSelection;
            } else {
              throw `id ${action.pageId} not found in pages`;
            }
          } else {
            throw `pages must come before selection`;
          }
        }
      } else {
        throw `options must come before selection`;
      }
      break;
    case "unselectAddress":
      if (draft.pages !== null) {
        if (draft.pages[action.pageId]) {
          draft.pages[action.pageId].selection = null;
        } else {
          throw `id ${action.pageId} not found in pages`;
        }
      } else {
        throw `pages must come before selection`;
      }
      break;
    case "deletePage":
      if (draft.pages !== null) {
        if (draft.pages[action.pageId]) {
          delete draft.pages[action.pageId];
        } else {
          throw `id ${action.pageId} not found in pages`;
        }
      } else {
        throw `pages must come before selection`;
      }
      break;
    case "commit":
      const val = {
        data: action.data,
        dataOut: getPageDataOutput(action.data),
        selection: action.selection,
      };
      if (draft.pages === null || action.isFirst) {
        draft.pages = {
          [action.key]: val,
        };
      } else {
        draft.pages[action.key] = val;
      }
      break;
  }
});
