/** @jsx jsx */
import { jsx } from "@emotion/core";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "components/pages/home";
import Saved from "components/pages/saved";
import Parse from "components/pages/parse";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/saved/:table/:rowId" component={Saved} />
        <Route exact path="/parse" component={Parse} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
