/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { Dispatch, useCallback } from "react";

import { Immutable } from "immer";

import { Option, Action } from "reducer";

import FormLabel from "components/utils/form_label";
import MultiSelectFilter from "components/utils/select/multiFilter";

const NearbyPlacesGaugingTenantSelect = ({
  tableOptions,
  rowOptions,
  maxSelection,
  dispatch,
}: {
  tableOptions: Immutable<Array<Option>>;
  rowOptions: Immutable<Array<string>>;
  maxSelection: number;
  dispatch: Dispatch<Action>;
}) => {
  const th = useTheme<Theme>();

  const handleGaugingTenantSelection = useCallback(
    (ids: Array<string>) => {
      dispatch({
        type: "updateGaugingTenants",
        val: ids,
      });
    },
    [dispatch]
  );

  return (
    <div
      css={{
        marginTop: th.space[8],
        maxWidth: th.sizes[12],
      }}
    >
      <FormLabel label={"gauging tenants"}>
        <div
          css={{
            minHeight: th.sizes[4],
          }}
        >
          <MultiSelectFilter
            options={tableOptions}
            handleSelection={handleGaugingTenantSelection}
            limit={maxSelection}
          />
        </div>
      </FormLabel>
    </div>
  );
};

export default NearbyPlacesGaugingTenantSelect;
