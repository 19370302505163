/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { useReducer, useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import Airtable from "airtable";

import { useAirtableApiStatus } from "components/utils/airtable_api_provider";

import reducer, { initialState, SavedState } from "reducer";

import Settings from "components/settings";
import Saved from "components/saved";
import ErrSaved from "components/saved/err";

const LoadSaved = () => {
  const th = useTheme<Theme>();

  const { table, rowId } = useParams();

  const savedInitialState = {
    ...initialState,
    saved: true,
  };

  const [state, dispatch] = useReducer(reducer, savedInitialState);
  const [errMsg, setErrMsg] = useState<string | null>(null);

  const airtableApiStatus = useAirtableApiStatus();
  const apiKey = airtableApiStatus.key;
  const apiBase = airtableApiStatus.base;

  useEffect(() => {
    if (apiKey && apiBase) {
      if (table && rowId) {
        const tableDecoded = decodeURIComponent(table);
        const rowIdDecoded = decodeURIComponent(rowId);
        const base = new Airtable({ apiKey: apiKey }).base(apiBase);
        base(tableDecoded)
          .find(rowIdDecoded)
          .then((i: any) => {
            const bm = i.fields["Baphomap Memories"];
            if (bm) {
              try {
                const bmObj = JSON.parse(bm) as SavedState;
                try {
                  dispatch({ type: "saved", val: bmObj });
                } catch {
                  setErrMsg(`"Baphomap Memories" data not in valid format`);
                }
              } catch {
                setErrMsg(`"Baphomap Memories" data can't be parsed to JSON`);
              }
            } else {
              setErrMsg(`table contains no "Baphomap Memories field`);
            }
          })
          .catch(() => {
            setErrMsg(
              `row not found... table and row id combination is likely invalid`
            );
          });
      } else {
        if (!table) {
          setErrMsg(
            `table name missing in url (urls should be formatted as http://[host]/[table]/[row id])`
          );
        }
        if (!rowId) {
          setErrMsg(
            `row id required in url (urls should be formatted as http://[host]/[table]/[row id])`
          );
        }
      }
    }
  }, [table, rowId, apiKey, apiBase, setErrMsg]);

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "column",
      }}
    >
      <div
        css={{
          marginBottom: th.space[12],
          width: "100%",
        }}
      >
        <Settings />
        {errMsg === null ? (
          <div>
            {state.queryInput &&
              state.geoResult &&
              state.placeResults &&
              state.placeResultsTable && (
                <Saved
                  queryInput={state.queryInput}
                  geoResult={state.geoResult}
                  placeResults={state.placeResults}
                  placeResultsTable={state.placeResultsTable}
                  dispatch={dispatch}
                  saved={state.saved}
                />
              )}
          </div>
        ) : (
          <ErrSaved err={errMsg} />
        )}
      </div>
    </div>
  );
};

export default LoadSaved;
