/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

const Input = ({
  value,
  onChangeHandler,
  label,
  inlineLabel,
  errorLabel,
}: {
  value: string;
  onChangeHandler: (x: string) => void;
  label: string;
  inlineLabel?: string;
  errorLabel?: string | null;
}) => {
  const th = useTheme<Theme>();
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p
        css={{
          ...th.modules.text.body,
          fontSize: th.fontSizes.smaller[1],
          paddingBottom: th.space[4],
        }}
      >
        {label}
      </p>
      <div
        css={{
          ...th.modules.input.frame,
          paddingLeft: th.space[1],
          fontSize: th.fontSizes.base,
          transition: "border 0.2s ease 0s, color 0.2s ease 0s",
          ":focus-within": {
            borderColor: th.semanticColors.foreground,
          },
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            css={{
              display: "flex",
              flexGrow: 1,
              minWidth: 0,
              maxWidth: "100%",
            }}
          >
            <input
              value={value}
              onChange={(e) => onChangeHandler(e.target.value)}
              css={{
                flexGrow: 1,
                minWidth: 0,
                fontSize: th.fontSizes.base,
                margin: th.space[6],
              }}
            />
          </div>
          {inlineLabel && (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                paddingRight: th.space[8],
                fontSize: th.fontSizes.smaller[2],
                fontWeight: th.fontWeights.medium,
                color: th.semanticColors.accents[5],
              }}
            >
              <p>{inlineLabel}</p>
            </div>
          )}
        </div>
      </div>
      <div>
        <p
          css={{
            ...th.modules.text.body,
            fontSize: th.fontSizes.smaller[3],
            color: th.semanticColors.error.base,
          }}
        >
          {errorLabel || "\u00A0"}
        </p>
      </div>
    </div>
  );
};

export default Input;
