/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { useReducer, useEffect } from "react";

import { produce, Immutable } from "immer";

import { XCircle, MoreHorizontal, Check } from "react-feather";

export type Option = { id: string; label: string };

const SingleSelectFilter = ({
  options,
  handleSelection,
  handleChange,
  placeholder = "",
  initSelection = undefined,
}: {
  options: Immutable<Array<Option>>;
  handleSelection: (id: string) => void;
  handleChange: () => void;
  placeholder?: string;
  initSelection?: Option;
}) => {
  const th = useTheme<Theme>();

  const [state, dispatch] = useReducer(reducer, options, init);

  useEffect(() => {
    if (initSelection) {
      dispatch({
        type: "selectOption",
        val: initSelection,
      });
    }
  }, [initSelection, dispatch]);

  useEffect(() => {
    handleChange();
  }, [state.searchValue, handleChange]);

  useEffect(() => {
    if (state.selectedId !== null) {
      handleSelection(state.selectedId);
    }
  }, [state.selectedId, handleSelection]);

  return (
    <div
      css={{
        ...th.modules.input.frame,
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        height: "inherit",
        paddingLeft: th.space[2],
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <input
          placeholder={placeholder}
          onFocus={() => dispatch({ type: "focus" })}
          onBlur={() => {
            dispatch({ type: "blur" });
          }}
          onChange={(e) =>
            dispatch({
              type: "changeSearchValue",
              val: e.target.value,
            })
          }
          value={state.searchValue}
          css={{
            flexGrow: 1,
            minWidth: 0,
            fontSize: th.fontSizes.smaller[1],
            fontWeight: th.fontWeights.normal,
          }}
        />
        <div
          css={{
            paddingTop: th.space[1],
            paddingLeft: th.space[7],
            paddingRight: th.space[7],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {state.isFocused || state.isMouseInClear ? (
            <XCircle
              color={th.semanticColors.accents[3]}
              size={th.fontSizes.larger[0]}
              onClick={() => dispatch({ type: "clear" })}
              onMouseEnter={() => dispatch({ type: "clearHoverIn" })}
              onMouseLeave={() => dispatch({ type: "clearHoverOut" })}
            />
          ) : state.selectedId === null ? (
            <MoreHorizontal
              color={th.semanticColors.warning.base}
              size={th.fontSizes.larger[0]}
            />
          ) : (
            <Check
              color={th.semanticColors.success.base}
              size={th.fontSizes.larger[0]}
            />
          )}
        </div>
      </div>

      {(state.isFocused || state.isMouseInMenu) && (
        <div
          onMouseEnter={() => dispatch({ type: "menuHoverIn" })}
          onMouseLeave={() => dispatch({ type: "menuHoverOut" })}
          css={{
            position: "absolute",
            top: "100%",
            zIndex: 100,
            ...th.modules.container.popover,
            width: "100%",
            marginTop: th.space[6],
            paddingTop: th.space[6],
            paddingBottom: th.space[6],
          }}
        >
          {state.filteredOptions.length > 0 ? (
            state.filteredOptions.slice(0, 100).map((i) => (
              <div
                onClick={() =>
                  dispatch({
                    type: "selectOption",
                    val: i,
                  })
                }
                key={i.id}
                css={{
                  marginLeft: th.space[1],
                  marginRight: th.space[1],
                  paddingTop: th.space[6],
                  paddingBottom: th.space[6],
                  paddingLeft: th.space[8],
                  paddingRight: th.space[6],
                  borderRadius: th.modules.container.popover.borderRadius,
                  ":hover": {
                    backgroundColor: th.semanticColors.accents[1],
                    fontWeight: th.fontWeights.medium,
                  },
                }}
              >
                <p
                  css={{
                    ...th.modules.text.body,
                    fontSize: th.fontSizes.smaller[1],
                  }}
                >
                  {i.label}
                </p>
              </div>
            ))
          ) : (
            <div
              css={{
                marginLeft: th.space[1],
                marginRight: th.space[1],
                paddingTop: th.space[6],
                paddingBottom: th.space[6],
                paddingLeft: th.space[8],
                paddingRight: th.space[6],
                borderRadius: th.modules.container.popover.borderRadius,
              }}
            >
              <p
                css={{
                  ...th.modules.text.body,
                  fontSize: th.fontSizes.smaller[1],
                }}
              >
                {"no matching results"}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

type State = {
  allOptions: Array<Option>;
  filteredOptions: Array<Option>;
  isFocused: boolean;
  isMouseInMenu: boolean;
  isMouseInClear: boolean;
  searchValue: string;
  selectedId: string | null;
};

type Action =
  | {
      type: "focus";
    }
  | {
      type: "blur";
    }
  | {
      type: "menuHoverIn";
    }
  | {
      type: "menuHoverOut";
    }
  | {
      type: "clearHoverIn";
    }
  | {
      type: "clearHoverOut";
    }
  | {
      type: "clear";
    }
  | {
      type: "changeSearchValue";
      val: string;
    }
  | {
      type: "selectOption";
      val: Option;
    };

const reducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case "focus":
      draft.isFocused = true;
      break;
    case "blur":
      draft.isFocused = false;
      break;
    case "menuHoverIn":
      draft.isMouseInMenu = true;
      break;
    case "menuHoverOut":
      draft.isMouseInMenu = false;
      break;
    case "clearHoverIn":
      draft.isMouseInClear = true;
      break;
    case "clearHoverOut":
      draft.isMouseInClear = false;
      break;
    case "clear":
      draft.searchValue = "";
      draft.filteredOptions = draft.allOptions;
      draft.selectedId = null;
      draft.isFocused = false;
      draft.isMouseInClear = false;
      break;
    case "changeSearchValue":
      draft.searchValue = action.val;
      const lowerCaseSearchVal = action.val.toLowerCase();
      draft.filteredOptions = draft.allOptions.filter((i) =>
        i.label.toLowerCase().includes(lowerCaseSearchVal)
      );
      draft.selectedId = null;
      break;
    case "selectOption":
      draft.searchValue = action.val.label;
      draft.filteredOptions = [action.val];
      draft.selectedId = action.val.id;
      draft.isFocused = false;
      draft.isMouseInMenu = false;
      break;
  }
});

const init = (options: Immutable<Array<Option>>): State => ({
  allOptions: [...options],
  filteredOptions: [...options],
  searchValue: "",
  isFocused: false,
  isMouseInMenu: false,
  isMouseInClear: false,
  selectedId: null,
});

export default SingleSelectFilter;
