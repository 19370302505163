/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { Dispatch } from "react";

import { Immutable } from "immer";

import { State, Action } from "reducer";

import DynamicMarginContainer from "components/utils/container/dynamic_margin";

import NearbyPlacesQueryForm from "./form";

const NearbyPlacesQuery = ({
  state,
  dispatch,
}: {
  state: Immutable<State>;
  dispatch: Dispatch<Action>;
}) => {
  const th = useTheme<Theme>();

  return (
    <div
      css={{
        backgroundColor: th.semanticColors.accents[0],
        paddingTop: th.space[8],
        paddingBottom: th.space[10],
      }}
    >
      <DynamicMarginContainer>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            css={{
              ...th.modules.text.body,
              fontSize: th.fontSizes.larger[0],
              fontWeight: th.fontWeights.medium,
              marginRight: th.space[8],
            }}
          >
            {"Nearby Places Search"}
          </p>
          <NearbyPlacesQueryForm state={state} dispatch={dispatch} />
        </div>
      </DynamicMarginContainer>
    </div>
  );
};

export default NearbyPlacesQuery;
