/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { Dispatch } from "react";

import { Immutable } from "immer";

import { State, Action } from "reducer";

import NearbyPlacesQuery from "./query";
import NearbyPlacesResults from "./results";

const NearbyPlaces = ({
  state,
  dispatch,
}: {
  state: Immutable<State>;
  dispatch: Dispatch<Action>;
}) => {
  const th = useTheme<Theme>();

  return (
    <div
      css={{
        width: "100%",
        borderBottomStyle: "solid",
        borderBottomWidth: th.borderWidths[1],
        borderTopStyle: "solid",
        borderTopWidth: th.borderWidths[1],
        borderColor: th.semanticColors.accents[2],
        marginTop: th.space[9],
      }}
    >
      <NearbyPlacesQuery state={state} dispatch={dispatch} />
      {state.tables &&
        state.tables.selection &&
        state.addresses &&
        state.addresses.selection &&
        state.queryInput && (
          <NearbyPlacesResults
            table={state.tables.selection.val.label}
            addressRowId={state.addresses.selection.val.id}
            queryInput={state.queryInput}
            geoResult={state.geoResult}
            placeResults={state.placeResults}
            placeResultsTable={state.placeResultsTable}
            dispatch={dispatch}
            saved={state.saved}
          />
        )}
    </div>
  );
};

export default NearbyPlaces;
