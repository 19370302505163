/** @jsx jsx */
import { jsx } from "@emotion/core";

import { Fragment, Dispatch, useState } from "react";

import { Immutable } from "immer";

import {
  QueryInput,
  GeoResult,
  PlaceResultMap,
  PlaceResultRecord,
  Action,
} from "reducer";

import NearbyPlacesResultsHeader from "./header";
import NearbyPlacesResultsTable from "./table";
import NearbyPlacesResultsMap from "./map";

const NearbyPlacesResultsDisplay = ({
  queryInput,
  geoResult,
  placeResults,
  placeResultsTable,
  dispatch,
  saved,
}: {
  queryInput: Immutable<QueryInput>;
  geoResult: Immutable<GeoResult>;
  placeResults: null | Immutable<PlaceResultMap>;
  placeResultsTable: null | Immutable<Array<PlaceResultRecord>>;
  dispatch: Dispatch<Action>;
  saved: boolean;
}) => {
  const [hoveredPlaceId, setHoveredPlaceId] = useState<string | null>(null);

  return (
    <Fragment>
      <NearbyPlacesResultsHeader
        queryInput={queryInput}
        geoResult={geoResult}
      />
      <NearbyPlacesResultsMap
        queryInput={queryInput}
        geoResult={geoResult}
        placeResults={placeResults}
        setHoveredPlaceId={setHoveredPlaceId}
        dispatch={dispatch}
        saved={saved}
      />
      <NearbyPlacesResultsTable
        placeResultsTable={placeResultsTable}
        hoveredPlaceId={hoveredPlaceId}
      />
    </Fragment>
  );
};

export default NearbyPlacesResultsDisplay;
