import React, {
  FunctionComponent,
  Dispatch,
  SetStateAction,
  createContext,
  useState,
  useContext,
} from "react";

import { useCookies } from "react-cookie";

import { Helmet } from "react-helmet";

const ApiStatusContext = createContext<{
  hasKey: boolean;
  loaded: boolean;
  authFailure: boolean;
  runtimeErr: boolean;
  setRuntimeErr: Dispatch<SetStateAction<boolean>>;
}>({
  hasKey: true,
  loaded: false,
  authFailure: false,
  runtimeErr: false,
  setRuntimeErr: () => {},
});

export const useGoogleApiStatus = () => {
  const context = useContext(ApiStatusContext);
  if (context === undefined) {
    throw new Error(
      "useGoogleApiStatus must be used within a GoogleApiProvider"
    );
  }
  return context;
};

const GoogleApiProvider: FunctionComponent = ({ children }) => {
  const [cookies] = useCookies();

  const [hasKey] = useState(cookies.googleKey && cookies.googleKey !== "");
  const [loaded, setLoaded] = useState(false);
  const [authFailure, setAuthFailure] = useState(false);
  const [runtimeErr, setRuntimeErr] = useState(false);

  window.gm_init = () => {
    setLoaded(true);
  };

  window.gm_authFailure = () => {
    setAuthFailure(true);
  };

  return (
    <div>
      {hasKey && (
        <Helmet>
          <script
            src={`https://maps.googleapis.com/maps/api/js?key=${cookies.googleKey}&libraries=places&callback=gm_init`}
            async
            defer
          />
        </Helmet>
      )}
      <ApiStatusContext.Provider
        value={{
          hasKey: hasKey,
          loaded: loaded,
          authFailure: authFailure,
          runtimeErr: runtimeErr,
          setRuntimeErr: setRuntimeErr,
        }}
      >
        {children}
      </ApiStatusContext.Provider>
    </div>
  );
};

export default GoogleApiProvider;
