/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { useState } from "react";

import { ChevronDown } from "react-feather";

export type Option = { id: string; label: string };

const SingleSelect = ({
  options,
  selection,
  handleSelection,
  placeholder = "",
}: {
  options: ReadonlyArray<Option>;
  selection: Option | null;
  handleSelection: (id: string) => void;
  placeholder?: string;
}) => {
  const th = useTheme<Theme>();

  const [showOptions, setShowOptions] = useState(false);

  return (
    <div
      onClick={() => setShowOptions(!showOptions)}
      css={{
        ...th.modules.input.frame,
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        height: "100%",
        fontSize: th.fontSizes.smaller[1],
        transition: "border 0.2s ease 0s, color 0.2s ease 0s",
      }}
    >
      <div
        css={{
          paddingLeft: th.space[6],
        }}
      >
        {selection ? (
          <p
            css={{
              ...th.modules.text.body,
            }}
          >
            {selection.label}
          </p>
        ) : (
          <p
            css={{
              ...th.modules.text.body,
              color: th.semanticColors.accents[5],
              fontWeight: th.fontWeights.light,
            }}
          >
            {placeholder}
          </p>
        )}
      </div>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          height: "50%",
          paddingTop: th.space[1],
          paddingRight: th.space[7],
          paddingLeft: th.space[7],
          borderLeftStyle: "solid",
          borderWidth: th.borderWidths[2],
          borderColor: th.modules.input.frame.borderColor,
          color: th.semanticColors.accents[4],
        }}
      >
        <ChevronDown size={th.fontSizes.larger[0]} />
      </div>
      {showOptions && (
        <div
          css={{
            position: "absolute",
            top: "100%",
            zIndex: 100,
            ...th.modules.container.popover,
            width: "100%",
            marginTop: th.space[6],
            paddingTop: th.space[7],
            paddingBottom: th.space[6],
          }}
        >
          {options.slice(0, 100).map((i) => (
            <div
              onClick={() => handleSelection(i.id)}
              key={i.id}
              css={{
                marginLeft: th.space[1],
                marginRight: th.space[1],
                paddingTop: th.space[6],
                paddingBottom: th.space[6],
                paddingLeft: th.space[8],
                paddingRight: th.space[6],
                borderRadius: th.modules.container.popover.borderRadius,
                ":hover": {
                  backgroundColor: th.semanticColors.accents[1],
                  fontWeight: th.fontWeights.medium,
                },
              }}
            >
              <p
                css={{
                  ...th.modules.text.body,
                }}
              >
                {i.label}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SingleSelect;
