/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { useEffect } from "react";

import { Immutable } from "immer";

import Airtable from "airtable";

import { AddressState, Option } from "reducer";

import FormLabel from "components/utils/form_label";
import Loading from "components/utils/loading";
import SingleSelectFilter from "components/utils/select/singleFilter";

const AddressSelect = ({
  table,
  addressState,
  tableRefreshCount,
  handleAddressOptionUpdate,
  handleAddressSelection,
  handleAddressChange,
  apiKey,
  apiBase,
}: {
  table: string;
  addressState: Immutable<AddressState> | null;
  tableRefreshCount: number;
  handleAddressOptionUpdate: (base: Airtable.Base, table: string) => void;
  handleAddressSelection: (id: string) => void;
  handleAddressChange: () => void;
  apiKey: string;
  apiBase: string;
}) => {
  const th = useTheme<Theme>();

  useEffect(() => {
    const base = new Airtable({ apiKey: apiKey }).base(apiBase);
    handleAddressOptionUpdate(base, table);
  }, [apiKey, apiBase, table, tableRefreshCount, handleAddressOptionUpdate]);

  return (
    <div
      css={{
        marginTop: th.space[8],
        width: th.sizes[12],
      }}
    >
      {addressState ? (
        <AddressSelectView
          options={addressState.options.map((i) => i.val)}
          handleAddressSelection={handleAddressSelection}
          handleAddressChange={handleAddressChange}
        />
      ) : (
        <Loading label={"addresses loading..."} />
      )}
    </div>
  );
};

export const AddressSelectView = ({
  options,
  handleAddressSelection,
  handleAddressChange,
  initSelection = undefined,
}: {
  options: Array<Option>;
  handleAddressSelection: (id: string) => void;
  handleAddressChange: () => void;
  initSelection?: Option;
}) => {
  const th = useTheme<Theme>();

  return (
    <FormLabel label={"address"}>
      <div
        css={{
          height: th.sizes[4],
        }}
      >
        <SingleSelectFilter
          options={options}
          handleSelection={handleAddressSelection}
          handleChange={handleAddressChange}
          initSelection={initSelection}
        />
      </div>
    </FormLabel>
  );
};

export default AddressSelect;
