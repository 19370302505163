/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { Immutable } from "immer";

import { Dispatch } from "react";

import {
  QueryInput,
  GeoResult,
  PlaceResultMap,
  PlaceResultRecord,
  Action,
} from "reducer";

import { useGoogleApiStatus } from "components/utils/google_api_provider";

import DynamicMarginContainer from "components/utils/container/dynamic_margin";
import NearbyPlacesResultsDisplay from "components/nearby_places/results/display";

const Saved = ({
  queryInput,
  geoResult,
  placeResults,
  placeResultsTable,
  dispatch,
  saved,
}: {
  queryInput: Immutable<QueryInput>;
  geoResult: Immutable<GeoResult>;
  placeResults: Immutable<PlaceResultMap>;
  placeResultsTable: Immutable<Array<PlaceResultRecord>>;
  dispatch: Dispatch<Action>;
  saved: boolean;
}) => {
  const th = useTheme<Theme>();

  const googleApiStatus = useGoogleApiStatus();

  return googleApiStatus.loaded ? (
    <div
      css={{
        backgroundColor: th.semanticColors.accents[1],
        paddingTop: th.space[8],
        paddingBottom: th.space[10],
        borderBottomStyle: "solid",
        borderBottomWidth: th.borderWidths[1],
        borderColor: th.semanticColors.accents[2],
      }}
    >
      <DynamicMarginContainer>
        <NearbyPlacesResultsDisplay
          queryInput={queryInput}
          geoResult={geoResult}
          placeResults={placeResults}
          placeResultsTable={placeResultsTable}
          dispatch={dispatch}
          saved={saved}
        />
      </DynamicMarginContainer>
    </div>
  ) : null;
};

export default Saved;
