/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { useEffect } from "react";

import { Immutable } from "immer";

import { Option } from "reducer";

import { RefreshCw } from "react-feather";

import Loading from "components/utils/loading";
import FormLabel from "components/utils/form_label";
import SingleSelect from "components/utils/select/single";

const TableSelect = ({
  options,
  selection,
  handleTableRequest,
  handleTableSelection,
  handleRefreshTableData,
  apiKey,
  apiBase,
}: {
  options: Immutable<Array<Option>> | null;
  selection: Immutable<Option> | null;
  handleTableRequest: (resp: Response) => void;
  handleTableSelection: (id: string) => void;
  handleRefreshTableData?: () => void;
  apiKey: string;
  apiBase: string;
}) => {
  const th = useTheme<Theme>();

  useEffect(() => {
    if (apiKey && apiBase) {
      fetch(
        `https://api.airtable.com/v2/meta/${apiBase}/?api_key=${apiKey}`
      ).then((resp) => {
        handleTableRequest(resp);
      });
    }
  }, [apiKey, apiBase, handleTableRequest]);

  return (
    <div
      css={{
        marginTop: th.space[8],
      }}
    >
      {options ? (
        <div
          css={{
            display: "flex",
          }}
        >
          <div
            css={{
              width: th.sizes[11],
            }}
          >
            <NearbyPlacesTableSelectView
              options={options}
              selection={selection}
              handleTableSelection={handleTableSelection}
            />
          </div>
          {handleRefreshTableData && selection && (
            <div
              css={{
                alignSelf: "flex-end",
                marginLeft: th.space[9],
                marginBottom: th.space[2],
                cursor: "pointer",
              }}
              onClick={() => handleRefreshTableData()}
            >
              <RefreshCw color={th.semanticColors.transparent[6]} />
            </div>
          )}
        </div>
      ) : (
        <Loading label={"tables loading..."} />
      )}
    </div>
  );
};

const NearbyPlacesTableSelectView = ({
  options,
  selection,
  handleTableSelection,
}: {
  options: Immutable<Array<Option>>;
  selection: Immutable<Option> | null;
  handleTableSelection: (id: string) => void;
}) => {
  const th = useTheme<Theme>();

  return (
    <FormLabel label={"source table"}>
      <div
        css={{
          height: th.sizes[4],
        }}
      >
        <SingleSelect
          options={options}
          selection={selection}
          handleSelection={handleTableSelection}
        />
      </div>
    </FormLabel>
  );
};

export default TableSelect;
