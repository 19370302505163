import chroma from "chroma-js";

const colors = {
  gray: chroma
    .scale(["white", "black"])
    .colors(100, null)
    .map((i) => i.css("hsl")),
};

const semanticColors = {
  background: "#FFF",
  foreground: "#000",
  transparent: [
    "hsla(0, 0%, 0%, .1)",
    "hsla(0, 0%, 0%, .2)",
    "hsla(0, 0%, 0%, .3)",
    "hsla(0, 0%, 0%, .4)",
    "hsla(0, 0%, 0%, .5)",
    "hsla(0, 0%, 0%, .6)",
    "hsla(0, 0%, 0%, .7)",
    "hsla(0, 0%, 0%, .8)",
    "hsla(0, 0%, 0%, .9)",
  ],
  accents: [
    "#FAFAFA",
    "#EAEAEA",
    "#999",
    "#888",
    "#666",
    "#444",
    "#333",
    "#111",
  ],
  success: {
    base: "#0070F3",
    light: "#3291FF",
    dark: "#0366D6",
  },
  error: {
    base: "#E00",
    light: "FF1A1A",
    dark: "#C00",
  },
  warning: {
    base: "#F5A623",
    light: "#F7B955",
    dark: "#F49B0B",
  },
};

const baseSize = 1;
const baseScale = 4 / 3;
const unit = "em";

const r = (s: number, n: number) => [...Array(n).keys()].map((i) => s + i);
const c = (n: number) => `${Math.pow(baseScale, n) * baseSize}${unit}`;

const breakpoints: Readonly<string[]> = ["640", "768", "1024", "1290"].map(
  (i) => `@media (min-width: ${i}px)`
);

const space: Readonly<string[]> = [`0${unit}`].concat(
  r(-8, 20).map((i) => c(i))
);

const sizes: Readonly<string[]> = [`0${unit}`].concat(
  r(0, 17).map((i) => c(i))
);

const borderWidths: Readonly<string[]> = [`0${unit}`].concat(
  r(-10, 6).map((i) => c(i))
);

const radii: Readonly<string[]> = [`0${unit}`].concat(
  r(-7, 8).map((i) => c(i))
);

const fontSizes = {
  base: "1em",
  smaller: [".9375em", ".875em", ".75em", ".625em", ".5em", ".375em"],
  larger: ["1.25em", "1.5em", "1.75em", "2em", "2.5em", "3em"],
} as const;

const letterSpacings = {
  base: "0em",
  compacted: ["-.01em", "-.03em", "-.05em", "-.08em", "-.12em"],
  expanded: [".01em", ".03em", ".05em", ".08em", ".12em"],
} as const;

const lineHeights = {
  paragraph: 1.75,
  body: 1.5,
  heading: 1.25,
  subheading: 1.125,
} as const;

const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
} as const;

const font =
  '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif';

const shadows = {
  ambient: [
    "0 1px 2px hsla(0, 0%, 0%, .24)",
    "0 2px 4px hsla(0, 0%, 0%, .12)",
    "0 3px 6px hsla(0, 0%, 0%, .10)",
    "0 5px 10px hsla(0, 0%, 0%, .5)",
    "",
  ],
  direct: [
    "0 1px 3px hsla(0, 0%, 0%, .12)",
    "0 3px 6px hsla(0, 0%, 0%, .15)",
    "0 10px 20px hsla(0, 0%, 0%, .15)",
    "0 15px 25px hsla(0, 0%, 0%, .15)",
    "0 20px 40px hsla(0, 0%, 0%, .2)",
  ],
} as const;

const modules = {
  text: {
    body: {
      fontFamily: font,
      lineHeight: lineHeights.body,
    },
    heading: {
      fontFamily: font,
      lineHeight: lineHeights.heading,
    },
    subheading: {
      fontFamily: font,
      lineHeight: lineHeights.subheading,
    },
    link: {
      cursor: "pointer",
      ":hover": { textDecoration: "underline" },
    },
  },
  image: {
    rounded: {
      borderRadius: "9999px",
    },
  },
  button: {
    base: {
      cursor: "pointer",
      display: "inline-block",
      textAlign: "center",
      height: "max-content",
      width: "100%",
    },
  },
  input: {
    frame: {
      backgroundColor: semanticColors.background,
      borderStyle: "solid",
      borderWidth: borderWidths[2],
      borderColor: semanticColors.accents[1],
      borderRadius: radii[3],
    },
  },
  container: {
    popover: {
      borderStyle: "solid",
      borderColor: semanticColors.transparent[0],
      borderWidth: borderWidths[2],
      borderRadius: radii[3],
      backgroundColor: semanticColors.background,
      boxShadow: `${shadows.ambient[2]}, ${shadows.direct[2]}`,
    },
  },
} as const;

const theme = {
  colors: colors,
  semanticColors: semanticColors,
  space: space,
  sizes: sizes,
  borderWidths: borderWidths,
  radii: radii,
  font: font,
  fontSizes: fontSizes,
  letterSpacings: letterSpacings,
  lineHeights: lineHeights,
  fontWeights: fontWeights,
  shadows: shadows,
  breakpoints: breakpoints,
  modules: modules,
} as const;

export type Theme = typeof theme;

export default theme;
