/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { Dispatch } from "react";

import { Action } from "reducer";

import FormLabel from "components/utils/form_label";

const NearbyPlacesRadiusInput = ({
  value,
  dispatch,
}: {
  value: string | null;
  dispatch: Dispatch<Action>;
}) => {
  const th = useTheme<Theme>();

  const handleUpdateSearchRadius = (x: string) => {
    if (x === "") {
      dispatch({ type: "updateSearchRadius", val: null });
    } else {
      if (/^\d*\.?\d*$/.test(x)) {
        dispatch({ type: "updateSearchRadius", val: x });
      }
    }
  };

  return (
    <div
      css={{
        marginTop: th.space[8],
      }}
    >
      <FormLabel label={"search radius"}>
        <div
          css={{
            width: th.sizes[8],
            height: th.sizes[4],
          }}
        >
          <div
            css={{
              ...th.modules.input.frame,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              height: "inherit",
            }}
          >
            <div
              css={{
                display: "flex",
                flexGrow: 1,
                minWidth: 0,
                maxWidth: "100%",
              }}
            >
              <input
                value={value || ""}
                onChange={(e) => handleUpdateSearchRadius(e.target.value)}
                css={{
                  flexGrow: 1,
                  minWidth: 0,
                  fontSize: th.fontSizes.smaller[1],
                  margin: th.space[6],
                }}
              />
            </div>
            <div
              css={{
                display: "flex",
                alignItems: "center",
                paddingRight: th.space[8],
                fontSize: th.fontSizes.smaller[2],
                fontWeight: th.fontWeights.medium,
                color: th.semanticColors.accents[5],
              }}
            >
              <p>{"miles"}</p>
            </div>
          </div>
        </div>
      </FormLabel>
    </div>
  );
};

export default NearbyPlacesRadiusInput;
