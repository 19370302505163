export const stringifyArr = (arr: ReadonlyArray<string>) => {
  let out = arr[0];
  if (arr.length > 1) {
    arr.slice(1).forEach((i, idx) => {
      if (idx === arr.length - 2) {
        out = `${out} and ${i}`;
      } else {
        out = `${out}, ${i}`;
      }
      return out;
    });
  }
  return out;
};
