import { produce } from "immer";

const gaugingTenantLabels = [
  {
    color: "#FD7567",
    iconUrl: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
  },
  {
    color: "#6991FD",
    iconUrl: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
  },
  {
    color: "#FDF569",
    iconUrl: "https://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
  },
  {
    color: "#FE992E",
    iconUrl: "https://maps.google.com/mapfiles/ms/icons/orange-dot.png",
  },
  {
    color: "#67DDDD",
    iconUrl: "https://maps.google.com/mapfiles/ms/icons/ltblue-dot.png",
  },
  {
    color: "#8E67FD",
    iconUrl: "https://maps.google.com/mapfiles/ms/icons/purple-dot.png",
  },
  {
    color: "#00E64D",
    iconUrl: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
  },
  {
    color: "#E661AC",
    iconUrl: "https://maps.google.com/mapfiles/ms/icons/pink-dot.png",
  },
];

export type Option = { id: string; label: string };

export type AddressOption = {
  val: Option;
  gaugingTenants: Array<string>;
};

export type TableOption = {
  val: Option;
  gaugingTenantOptions: Array<Option>;
};

export type TableState = {
  options: Array<TableOption>;
  selection: null | TableOption;
  selectionRefreshCount: number;
};

export type AddressState = {
  options: Array<AddressOption>;
  selection: null | AddressOption;
};

export type QueryInput = {
  address: string;
  gaugingTenants: Array<string>;
  searchRadius: string;
};

export type GeoResult = {
  placeId: string;
  address: string;
  gps: {
    lat: number;
    lng: number;
  };
};

export type PlaceResultItem = {
  placeId: string;
  address: string;
  name: string;
  gps: {
    lat: number;
    lng: number;
  };
  fromOrigin: {
    distance: number;
  };
};

export type PlaceResultItemMap = {
  [key: string]: Array<PlaceResultItem>;
};

export type PlaceResultMap = {
  [key: string]: {
    items: Array<PlaceResultItem>;
    iconUrl: string;
    color: string;
  };
};

export type PlaceResultRecord = {
  gaugingTenant: string;
  placeId: string;
  name: string;
  address: string;
  distance: number;
  color: string;
};

export type SavedState = {
  v: string;
  queryInput: QueryInput;
  geoResult: GeoResult;
  placeResults: PlaceResultMap;
};

export type State = {
  saved: boolean;
  tables: null | TableState;
  addresses: null | AddressState;
  gaugingTenants: null | Array<string>;
  searchRadius: null | string;
  queryInput: null | QueryInput;
  geoResult: null | GeoResult;
  placeResults: null | PlaceResultMap;
  placeResultsTable: null | Array<PlaceResultRecord>;
  utils: {
    gaugingTenantLabels: typeof gaugingTenantLabels;
  };
};

export const initialState = {
  saved: false,
  tables: null,
  addresses: null,
  gaugingTenants: null,
  searchRadius: null,
  queryInput: null,
  geoResult: null,
  placeResults: null,
  placeResultsTable: null,
  utils: {
    gaugingTenantLabels: gaugingTenantLabels,
  },
};

export type Action =
  | {
      type: "saved";
      val: SavedState;
    }
  | {
      type: "updateTableOptions";
      val: Array<TableOption>;
    }
  | {
      type: "selectTable";
      id: string;
    }
  | {
      type: "refreshTableData";
    }
  | {
      type: "updateAddressOptions";
      val: Array<AddressOption>;
    }
  | {
      type: "selectAddress";
      id: string;
    }
  | {
      type: "deleteAddress";
    }
  | {
      type: "updateGaugingTenants";
      val: Array<string>;
    }
  | {
      type: "updateSearchRadius";
      val: string | null;
    }
  | {
      type: "updateQueryInput";
      val: QueryInput | null;
    }
  | {
      type: "geoResult";
      val: GeoResult | null;
    }
  | {
      type: "placeResults";
      val: PlaceResultItemMap;
    };

const reducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case "saved":
      draft.queryInput = action.val.queryInput;
      draft.geoResult = action.val.geoResult;
      draft.placeResults = action.val.placeResults;
      draft.placeResultsTable = getPlaceResultsTable(draft.placeResults);
      draft.saved = true;
      break;
    case "updateTableOptions":
      draft.tables = {
        options: action.val,
        selection: null,
        selectionRefreshCount: 0,
      };
      draft.addresses = null;
      draft.gaugingTenants = null;
      draft.searchRadius = null;
      draft.queryInput = null;
      draft.geoResult = null;
      draft.placeResults = null;
      draft.placeResultsTable = null;
      break;
    case "selectTable":
      if (draft.tables === null) {
        throw `${action.type}: table options must be set`;
      } else {
        const optionSelection = draft.tables.options.find(
          (i) => i.val.id === action.id
        );
        if (optionSelection === undefined) {
          throw `id ${action.id} not found in table options`;
        } else {
          draft.tables.selection = optionSelection;
          draft.tables.selectionRefreshCount = 0;
          draft.addresses = null;
          draft.gaugingTenants = null;
          draft.searchRadius = null;
          draft.queryInput = null;
          draft.geoResult = null;
          draft.placeResults = null;
          draft.placeResultsTable = null;
        }
      }
      break;
    case "refreshTableData":
      if (draft.tables === null) {
        throw `${action.type}: table options must be set`;
      } else {
        draft.tables.selectionRefreshCount++;
        draft.addresses = null;
        draft.gaugingTenants = null;
        draft.searchRadius = null;
        draft.queryInput = null;
        draft.geoResult = null;
        draft.placeResults = null;
        draft.placeResultsTable = null;
      }
      break;
    case "updateAddressOptions":
      if (draft.tables === null) {
        throw `${action.type}: table options must be set`;
      } else if (draft.tables.selection === null) {
        throw `${action.type}: table selection must be set`;
      } else {
        draft.addresses = {
          options: action.val,
          selection: null,
        };
        draft.gaugingTenants = null;
        draft.searchRadius = null;
        draft.queryInput = null;
        draft.geoResult = null;
        draft.placeResults = null;
        draft.placeResultsTable = null;
      }
      break;
    case "selectAddress":
      if (draft.tables === null) {
        throw `${action.type}: table options must be set`;
      } else if (draft.tables.selection === null) {
        throw `${action.type}: table selection must be set`;
      } else if (draft.addresses === null) {
        throw `${action.type}: address options must be set`;
      } else {
        const optionSelection = draft.addresses.options.find(
          (i) => i.val.id === action.id
        );
        if (optionSelection === undefined) {
          throw `id ${action.id} not found in address options`;
        } else {
          draft.addresses.selection = optionSelection;
          draft.gaugingTenants = null;
          draft.searchRadius = null;
          draft.queryInput = null;
          draft.geoResult = null;
          draft.placeResults = null;
          draft.placeResultsTable = null;
        }
      }
      break;
    case "deleteAddress":
      if (draft.tables === null) {
        throw `${action.type}: table options must be set`;
      } else if (draft.tables.selection === null) {
        throw `${action.type}: table selection must be set`;
      } else if (draft.addresses === null) {
        throw `${action.type}: address options must be set`;
      } else {
        draft.addresses.selection = null;
        draft.gaugingTenants = null;
        draft.searchRadius = null;
        draft.queryInput = null;
        draft.geoResult = null;
        draft.placeResults = null;
        draft.placeResultsTable = null;
      }
      break;
    case "updateGaugingTenants":
      if (draft.tables === null) {
        throw `${action.type}: table options must be set`;
      } else if (draft.tables.selection === null) {
        throw `${action.type}: table selection must be set`;
      } else if (draft.addresses === null) {
        throw `${action.type}: address options must be set`;
      } else if (draft.addresses.selection === null) {
        throw `${action.type}: address selection must be set`;
      } else {
        draft.gaugingTenants = action.val;
        draft.searchRadius = null;
        draft.queryInput = null;
        draft.geoResult = null;
        draft.placeResults = null;
        draft.placeResultsTable = null;
      }
      break;
    case "updateSearchRadius":
      draft.searchRadius = action.val;
      draft.queryInput = null;
      draft.geoResult = null;
      draft.placeResults = null;
      draft.placeResultsTable = null;
      break;
    case "updateQueryInput":
      draft.queryInput = action.val;
      draft.geoResult = null;
      draft.placeResults = null;
      draft.placeResultsTable = null;
      break;
    case "geoResult":
      draft.geoResult = action.val;
      if (action.val === null) {
        draft.placeResults = null;
        draft.placeResultsTable = null;
      }
      break;
    case "placeResults":
      const prEntries = Object.entries(action.val);
      if (prEntries.length > draft.utils.gaugingTenantLabels.length) {
        throw `number of gauging tenants cannot exceed ${draft.utils.gaugingTenantLabels.length}`;
      } else {
        // map form (used for markers)
        draft.placeResults = prEntries.reduce((acc, [k, v], idx) => {
          acc[k] = {
            items: v,
            color: draft.utils.gaugingTenantLabels[idx].color,
            iconUrl: draft.utils.gaugingTenantLabels[idx].iconUrl,
          };
          return acc;
        }, {} as PlaceResultMap);
        // arr form (used for table)
        draft.placeResultsTable = getPlaceResultsTable(draft.placeResults);
      }
      break;
  }
});

export default reducer;

const getPlaceResultsTable = (pr: PlaceResultMap): Array<PlaceResultRecord> => {
  const prt = Object.entries(pr).flatMap(([k, v]) =>
    v.items.map((j) => ({
      gaugingTenant: k,
      placeId: j.placeId,
      name: j.name,
      address: j.address,
      distance: j.fromOrigin.distance,
      color: v.color,
    }))
  );
  prt.sort((a, b) => {
    if (a.distance < b.distance) {
      return -1;
    } else if (a.distance > b.distance) {
      return 1;
    } else {
      return 0;
    }
  });
  return prt;
};
