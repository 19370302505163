/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { useReducer } from "react";

import reducer, { initialState } from "reducer";

import Settings from "components/settings";
import Address from "components/address";
import NearbyPlaces from "components/nearby_places";

const Home = () => {
  const th = useTheme<Theme>();

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div
      css={{
        display: "flex",
        justifyContent: "column",
      }}
    >
      <div
        css={{
          marginBottom: th.space[12],
          width: "100%",
        }}
      >
        <Settings />
        <Address state={state} dispatch={dispatch} />
        {state.tables?.selection && state.addresses?.selection && (
          <NearbyPlaces state={state} dispatch={dispatch} />
        )}
      </div>
    </div>
  );
};

export default Home;
