/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { Fragment } from "react";

import { Immutable } from "immer";

import { PlaceResultRecord } from "reducer";

const NearbyPlacesResultsTable = ({
  placeResultsTable,
  hoveredPlaceId,
}: {
  placeResultsTable: Immutable<Array<PlaceResultRecord>> | null;
  hoveredPlaceId: string | null;
}) => {
  const th = useTheme<Theme>();

  return (
    <Fragment>
      {placeResultsTable && placeResultsTable.length > 0 && (
        <div
          css={{
            display: "grid",
            gridTemplateColumns: `repeat(4, auto)`,
            width: "100%",
            borderStyle: "solid",
            borderWidth: th.borderWidths[3],
            borderRadius: th.radii[2],
            borderColor: th.semanticColors.accents[5],
            backgroundColor: th.semanticColors.background,
            marginBottom: th.space[10],
          }}
        >
          <ResultsHeaderCell label={"Search Term"} />
          <ResultsHeaderCell label={"Name"} />
          <ResultsHeaderCell label={"Location"} />
          <ResultsHeaderCell label={"Miles"} isLast />
          {placeResultsTable.map((i, idx) => {
            const isLast = placeResultsTable.length === idx + 1;
            const isHovered = i.placeId === hoveredPlaceId;
            return (
              <Fragment key={i.placeId}>
                <ResultsBodyCell
                  value={i.gaugingTenant}
                  color={i.color}
                  isHovered={isHovered}
                  isLastRow={isLast}
                />
                <ResultsBodyCell
                  value={i.name}
                  color={i.color}
                  isHovered={isHovered}
                  isLastRow={isLast}
                />
                <ResultsBodyCell
                  value={i.address}
                  color={i.color}
                  isHovered={isHovered}
                  isLastRow={isLast}
                />
                <ResultsBodyCell
                  value={i.distance.toFixed(2)}
                  color={i.color}
                  isHovered={isHovered}
                  isLastRow={isLast}
                  isLastCol
                />
              </Fragment>
            );
          })}
        </div>
      )}
    </Fragment>
  );
};

const ResultsHeaderCell = ({
  label,
  isLast,
}: {
  label: string;
  isLast?: boolean;
}) => {
  const th = useTheme<Theme>();
  return (
    <div
      css={{
        paddingTop: th.space[8],
        paddingBottom: th.space[6],
        paddingLeft: th.space[9],
        paddingRight: th.space[12],
        borderBottomStyle: "solid",
        borderRightStyle: "solid",
        borderWidth: th.borderWidths[2],
        borderBottomColor: th.semanticColors.accents[5],
        borderRightColor: isLast
          ? th.semanticColors.accents[5]
          : th.semanticColors.accents[3],
      }}
    >
      <p
        css={{
          ...th.modules.text.body,
          fontSize: th.fontSizes.smaller[1],
          fontWeight: th.fontWeights.medium,
        }}
      >
        {label}
      </p>
    </div>
  );
};

const ResultsBodyCell = ({
  value,
  color,
  isHovered,
  isLastCol,
  isLastRow,
}: {
  value: string;
  color: string;
  isHovered?: boolean;
  isLastCol?: boolean;
  isLastRow?: boolean;
}) => {
  const th = useTheme<Theme>();
  return (
    <div
      css={{
        backgroundColor: isHovered ? `${color}CC` : `${color}66`,
        paddingTop: th.space[5],
        paddingBottom: th.space[5],
        paddingLeft: th.space[9],
        paddingRight: th.space[8],
        borderBottomStyle: "solid",
        borderRightStyle: "solid",
        borderWidth: th.borderWidths[2],
        borderBottomColor: isLastRow
          ? th.semanticColors.accents[5]
          : th.semanticColors.accents[3],
        borderRightColor: isLastCol
          ? th.semanticColors.accents[5]
          : th.semanticColors.accents[3],
      }}
    >
      <p
        css={{
          ...th.modules.text.body,
          fontSize: th.fontSizes.smaller[2],
        }}
      >
        {value}
      </p>
    </div>
  );
};

export default NearbyPlacesResultsTable;
