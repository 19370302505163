/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { FunctionComponent } from "react";

type Props = {
  baseWidthOverride?: string;
};

const DynamicMarginContainer: FunctionComponent<Props> = ({
  baseWidthOverride,
  children,
}) => {
  const th = useTheme<Theme>();
  const baseWidth = baseWidthOverride || th.sizes[14];
  const maxMargin = th.space[14];
  const minMargin = th.space[8];
  return (
    <div
      css={{
        width: "100%",
        maxWidth: `calc(${baseWidth} + ${maxMargin})`,
      }}
    >
      <div css={{ marginLeft: minMargin, marginRight: minMargin }}>
        <div
          css={{
            maxWidth: baseWidth,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default DynamicMarginContainer;
