/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import BarLoader from "react-spinners/BarLoader";

import FormLabel from "components/utils/form_label";

const Loading = ({ label }: { label: string }) => {
  const th = useTheme<Theme>();

  return (
    <FormLabel label={label}>
      <div
        css={{
          marginTop: th.space[4],
          marginLeft: th.space[1],
        }}
      >
        <BarLoader width={th.sizes[9]} />
      </div>
    </FormLabel>
  );
};

export default Loading;
