import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { CookiesProvider } from "react-cookie";

import { ThemeProvider } from "emotion-theming";
import { Global, css } from "@emotion/core";

import { Helmet } from "react-helmet";

import theme from "style/theme";

import AirtableApiProvider from "components/utils/airtable_api_provider";
import GoogleApiProvider from "components/utils/google_api_provider";

const globalStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body {
    background-color: ${theme.semanticColors.background};
    font-family: ${theme.font};
  }
  input {
    border: none;
    outline: none;
    margin: ${theme.space[5]};
  }
  button {
    border: none;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <Helmet>
      <meta http-equiv="content-type" content="text/html; charset=utf-8" />
    </Helmet>
    <CookiesProvider>
      <AirtableApiProvider>
        <GoogleApiProvider>
          <ThemeProvider<typeof theme> theme={theme}>
            <Global styles={globalStyles} />
            <App />
          </ThemeProvider>
        </GoogleApiProvider>
      </AirtableApiProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
