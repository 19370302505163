/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { Dispatch, Fragment, useState } from "react";

import { Immutable } from "immer";

import { GoogleMap, Marker, Circle } from "@react-google-maps/api";

import { AlertTriangle } from "react-feather";

import {
  QueryInput,
  GeoResult,
  PlaceResultItemMap,
  PlaceResultMap,
  Action,
} from "reducer";

import { useGoogleApiStatus } from "components/utils/google_api_provider";

const MILES_TO_METERS = 1609.34;

const calcDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  // miles
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist;
  }
};

const NearbyPlacesResultsMap = ({
  queryInput,
  geoResult,
  placeResults,
  setHoveredPlaceId,
  dispatch,
  saved,
}: {
  queryInput: Immutable<QueryInput>;
  geoResult: Immutable<GeoResult>;
  placeResults: null | Immutable<PlaceResultMap>;
  setHoveredPlaceId: Dispatch<string | null>;
  dispatch: Dispatch<Action>;
  saved: boolean;
}) => {
  const th = useTheme<Theme>();

  const { setRuntimeErr: setApiRuntimeErr } = useGoogleApiStatus();

  const [noResults, setNoResults] = useState(false);

  const radiusVal = parseFloat(queryInput.searchRadius) || 0;

  const gmaps = window.google.maps;

  // origin
  const location = new gmaps.LatLng(geoResult.gps.lat, geoResult.gps.lng);

  return (
    <Fragment>
      {geoResult && (
        <div
          css={{
            marginBottom: th.space[9],
          }}
        >
          <div
            css={{
              position: "relative",
              minWidth: 0,
              width: "100%",
              paddingTop: `${(9 / 16) * 100}%`,
              borderStyle: "solid",
              borderTopWidth: th.borderWidths[3],
              borderLeftWidth: th.borderWidths[3],
              borderBottomWidth: `calc(${th.borderWidths[3]} * 2)`,
              borderRightWidth: `calc(${th.borderWidths[3]} * 2)`,
              borderColor: th.semanticColors.accents[5],
              borderRadius: th.radii[2],
            }}
          >
            <div
              css={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "100%",
                borderRadius: th.radii[3],
              }}
            >
              <GoogleMap
                onLoad={(map) => {
                  if (!saved) {
                    setNoResults(false);
                    // data container
                    let val: PlaceResultItemMap = {};
                    // run for each gauging tenant
                    queryInput.gaugingTenants.forEach((i, idx) => {
                      // get places
                      const placesRequest = {
                        location: location,
                        keyword: i,
                        rankBy: gmaps.places.RankBy.DISTANCE,
                      };
                      const placesService = new gmaps.places.PlacesService(map);
                      placesService.nearbySearch(placesRequest, (pres, pst) => {
                        if (
                          [
                            gmaps.places.PlacesServiceStatus.OK,
                            gmaps.places.PlacesServiceStatus.ZERO_RESULTS,
                          ].includes(pst)
                        ) {
                          // only include places w/ all necessary fields
                          const placesResult = pres
                            .filter(
                              (i) =>
                                i.place_id && i.vicinity && i.name && i.geometry
                            )
                            .map((i) => ({
                              placeId: i.place_id!,
                              address: i.vicinity!,
                              name: i.name!,
                              gps: {
                                lat: i.geometry!.location.lat(),
                                lng: i.geometry!.location.lng(),
                              },
                            }));
                          // get distance from origin to each place
                          const placesDistancesResult = placesResult.map(
                            (i) => ({
                              ...i,
                              fromOrigin: {
                                distance: calcDistance(
                                  i.gps.lat,
                                  i.gps.lng,
                                  geoResult.gps.lat,
                                  geoResult.gps.lng
                                ),
                              },
                            })
                          );
                          const boundPlacesDistancesResult = placesDistancesResult.filter(
                            (i) => i.fromOrigin.distance <= radiusVal
                          );
                          val = {
                            ...val,
                            [i]: boundPlacesDistancesResult,
                          };
                          if (
                            Object.keys(val).length ===
                            queryInput.gaugingTenants.length
                          ) {
                            const hasResults = Object.values(val).some(
                              (i) => i.length > 0
                            );
                            setNoResults(!hasResults);
                            dispatch({ type: "placeResults", val: val });
                          }
                        } else {
                          console.log(pst);
                          setApiRuntimeErr(true);
                        }
                      });
                    });
                  }
                }}
                mapContainerStyle={{
                  height: "100%",
                  width: "100%",
                }}
                zoom={
                  radiusVal >= 32000 / MILES_TO_METERS
                    ? 9
                    : radiusVal >= 16000 / MILES_TO_METERS
                    ? 10
                    : radiusVal >= 8000 / MILES_TO_METERS
                    ? 11
                    : radiusVal >= 4000 / MILES_TO_METERS
                    ? 12
                    : radiusVal >= 2000 / MILES_TO_METERS
                    ? 13
                    : 14
                }
                center={geoResult.gps}
              >
                <Circle
                  center={location}
                  radius={radiusVal * MILES_TO_METERS}
                  options={{
                    strokeWeight: 2,
                  }}
                />
                <Marker
                  position={geoResult.gps}
                  icon={"https://maps.google.com/mapfiles/kml/pal4/icon58.png"}
                />
                {placeResults &&
                  queryInput.gaugingTenants
                    .filter((i) => placeResults.hasOwnProperty(i))
                    .flatMap((i) =>
                      placeResults[i].items.map((j) => (
                        <Marker
                          key={j.placeId}
                          position={j.gps}
                          icon={placeResults[i].iconUrl}
                          onMouseOver={() => setHoveredPlaceId(j.placeId)}
                          onMouseOut={() => setHoveredPlaceId(null)}
                        />
                      ))
                    )}
              </GoogleMap>
            </div>
          </div>
          {!noResults && placeResults && (
            <div
              css={{
                display: "flex",
                alignItems: "center",
                marginTop: th.space[7],
              }}
            >
              {queryInput.gaugingTenants.map((i, idx) => (
                <LegendItem key={idx} label={i} color={placeResults[i].color} />
              ))}
            </div>
          )}
        </div>
      )}
      {noResults && (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            css={{
              flexDirection: "row",
              alignItems: "center",
              fontSize: th.fontSizes.smaller[5],
              marginRight: th.space[9],
              paddingTop: "2px",
            }}
          >
            <AlertTriangle size={th.fontSizes.larger[4]} />
          </div>
          <p
            css={{
              ...th.modules.text.body,
              fontSize: th.fontSizes.smaller[0],
              fontWeight: th.fontWeights.medium,
            }}
          >
            {"No Results In Search Radius"}
          </p>
        </div>
      )}
    </Fragment>
  );
};

const LegendItem = ({ label, color }: { label: string; color: string }) => {
  const th = useTheme<Theme>();
  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        marginRight: th.space[7],
        marginTop: th.space[3],
      }}
    >
      <div
        css={{
          height: th.space[8],
          width: th.space[8],
          borderStyle: "solid",
          borderWidth: th.borderWidths[2],
          borderRadius: th.radii[3],
          backgroundColor: color,
          marginRight: th.space[5],
        }}
      />
      <p
        css={{
          fontSize: th.fontSizes.smaller[2],
          fontWeight: th.fontWeights.medium,
        }}
      >
        {label}
      </p>
    </div>
  );
};

export default NearbyPlacesResultsMap;
