import React, {
  FunctionComponent,
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

import { useCookies } from "react-cookie";

const ApiStatusContext = createContext<{
  key: string | null;
  base: string | null;
  tablesLoaded: boolean;
  tableDataLoaded: boolean;
  setTablesLoaded: Dispatch<SetStateAction<boolean>>;
  setTableDataLoaded: Dispatch<SetStateAction<boolean>>;
}>({
  key: null,
  base: null,
  tablesLoaded: false,
  tableDataLoaded: false,
  setTablesLoaded: () => {},
  setTableDataLoaded: () => {},
});

export const useAirtableApiStatus = () => {
  const context = useContext(ApiStatusContext);
  if (context === undefined) {
    throw new Error(
      "useAirtableApiStatus must be used within an AirtableApiProvider"
    );
  }
  return context;
};

const AirtableApiProvider: FunctionComponent = ({ children }) => {
  const [cookies] = useCookies();

  const key =
    cookies.airtableKey && cookies.airtableKey !== ""
      ? cookies.airtableKey
      : null;
  const base =
    cookies.airtableBase && cookies.airtableBase !== ""
      ? cookies.airtableBase
      : null;

  const [tablesLoaded, setTablesLoaded] = useState(false);
  const [tableDataLoaded, setTableDataLoaded] = useState(false);

  return (
    <ApiStatusContext.Provider
      value={{
        key: key,
        base: base,
        tablesLoaded: tablesLoaded,
        tableDataLoaded: tableDataLoaded,
        setTablesLoaded: setTablesLoaded,
        setTableDataLoaded: setTableDataLoaded,
      }}
    >
      {children}
    </ApiStatusContext.Provider>
  );
};

export default AirtableApiProvider;
