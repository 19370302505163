/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { useReducer, useEffect, useRef } from "react";

import { produce, Immutable } from "immer";

import { X, XCircle, MoreHorizontal } from "react-feather";

export type Option = { id: string; label: string };

const MultiSelectFilter = ({
  options,
  handleSelection,
  placeholder = "",
  limit = null,
}: {
  options: Immutable<Array<Option>>;
  handleSelection: (id: Array<string>) => void;
  placeholder?: string;
  limit?: number | null;
}) => {
  const th = useTheme<Theme>();

  const [state, dispatch] = useReducer(reducer, options, init);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    handleSelection(state.selectedIds as Array<string>);
  }, [state.selectedIds, handleSelection]);

  const hasCapacity = limit === null || state.selectedIds.length < limit;

  return (
    <div
      css={{
        ...th.modules.input.frame,
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        height: "100%",
        minHeight: "inherit",
        paddingLeft: th.space[2],
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          css={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginTop: `-${th.space[4]}`,
            paddingTop: th.space[4],
            paddingBottom: th.space[4],
          }}
        >
          {state.allOptions
            .filter((i) => state.selectedIds.includes(i.id))
            .map((i) => (
              <div
                key={i.id}
                css={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginLeft: th.space[4],
                  marginTop: th.space[4],
                  paddingTop: th.space[2],
                  paddingBottom: th.space[2],
                  paddingLeft: th.space[5],
                  paddingRight: th.space[5],
                  borderRadius: th.radii[2],
                  borderStyle: "solid",
                  borderWidth: th.borderWidths[2],
                  borderColor: th.semanticColors.transparent[2],
                  backgroundColor: th.semanticColors.accents[1],
                }}
              >
                <p
                  css={{
                    ...th.modules.text.body,
                    fontSize: th.fontSizes.smaller[2],
                  }}
                >
                  {i.label}
                </p>
                <div
                  css={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingLeft: th.space[5],
                  }}
                >
                  <XCircle
                    size={th.fontSizes.smaller[2]}
                    onClick={() => dispatch({ type: "unselectOption", val: i })}
                  />
                </div>
              </div>
            ))}
          {hasCapacity && (
            <input
              placeholder={placeholder}
              onClick={() =>
                inputRef && inputRef.current && inputRef.current.focus()
              }
              onFocus={() => dispatch({ type: "focus" })}
              onBlur={() => {
                dispatch({ type: "blur" });
              }}
              onChange={(e) =>
                dispatch({
                  type: "changeSearchValue",
                  val: e.target.value,
                })
              }
              value={state.searchValue}
              ref={inputRef}
              css={{
                flexGrow: 1,
                minWidth: "1ch",
                width: `${state.searchValue.length}ch`,
                marginTop: th.space[7],
                fontSize: th.fontSizes.smaller[1],
                fontWeight: th.fontWeights.normal,
              }}
            />
          )}
        </div>
        <div
          css={{
            paddingLeft: th.space[7],
            paddingRight: th.space[7],
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {state.selectedIds.length > 0 ? (
            <X
              color={th.semanticColors.accents[3]}
              size={th.fontSizes.larger[0]}
              onClick={() => dispatch({ type: "clear" })}
              onMouseEnter={() => dispatch({ type: "clearHoverIn" })}
              onMouseLeave={() => dispatch({ type: "clearHoverOut" })}
            />
          ) : (
            <MoreHorizontal
              color={th.semanticColors.warning.base}
              size={th.fontSizes.larger[0]}
            />
          )}
        </div>
      </div>
      {hasCapacity && (state.isFocused || state.isMouseInMenu) && (
        <div
          onMouseEnter={() => dispatch({ type: "menuHoverIn" })}
          onMouseLeave={() => dispatch({ type: "menuHoverOut" })}
          css={{
            position: "absolute",
            zIndex: 100,
            top: "100%",
            ...th.modules.container.popover,
            width: "100%",
            marginTop: th.space[6],
            paddingTop: th.space[6],
            paddingBottom: th.space[6],
          }}
        >
          {state.filteredOptions.length > 0 ? (
            state.filteredOptions.slice(0, 100).map((i) => (
              <div
                onClick={() =>
                  dispatch({
                    type: "selectOption",
                    val: i,
                  })
                }
                key={i.id}
                css={{
                  marginLeft: th.space[1],
                  marginRight: th.space[1],
                  paddingTop: th.space[6],
                  paddingBottom: th.space[6],
                  paddingLeft: th.space[8],
                  paddingRight: th.space[6],
                  borderRadius: th.modules.container.popover.borderRadius,
                  ":hover": {
                    backgroundColor: th.semanticColors.accents[1],
                    fontWeight: th.fontWeights.medium,
                  },
                }}
              >
                <p
                  css={{
                    ...th.modules.text.body,
                    fontSize: th.fontSizes.smaller[1],
                  }}
                >
                  {i.label}
                </p>
              </div>
            ))
          ) : (
            <div
              css={{
                marginLeft: th.space[1],
                marginRight: th.space[1],
                paddingTop: th.space[6],
                paddingBottom: th.space[6],
                paddingLeft: th.space[8],
                paddingRight: th.space[6],
                borderRadius: th.modules.container.popover.borderRadius,
              }}
            >
              <p
                css={{
                  ...th.modules.text.body,
                  fontSize: th.fontSizes.smaller[1],
                }}
              >
                {"no matching results"}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

type State = {
  allOptions: Array<Option>;
  filteredOptions: Array<Option>;
  isFocused: boolean;
  isMouseInMenu: boolean;
  isMouseInClear: boolean;
  searchValue: string;
  selectedIds: Array<string>;
};

type Action =
  | {
      type: "focus";
    }
  | {
      type: "blur";
    }
  | {
      type: "menuHoverIn";
    }
  | {
      type: "menuHoverOut";
    }
  | {
      type: "clearHoverIn";
    }
  | {
      type: "clearHoverOut";
    }
  | {
      type: "clear";
    }
  | {
      type: "changeSearchValue";
      val: string;
    }
  | {
      type: "selectOption";
      val: Option;
    }
  | {
      type: "unselectOption";
      val: Option;
    };

const reducer = produce((draft: State, action: Action) => {
  switch (action.type) {
    case "focus":
      draft.isFocused = true;
      break;
    case "blur":
      draft.isFocused = false;
      break;
    case "menuHoverIn":
      draft.isMouseInMenu = true;
      break;
    case "menuHoverOut":
      draft.isMouseInMenu = false;
      break;
    case "clearHoverIn":
      draft.isMouseInClear = true;
      break;
    case "clearHoverOut":
      draft.isMouseInClear = false;
      break;
    case "clear":
      draft.searchValue = "";
      draft.filteredOptions = draft.allOptions;
      draft.selectedIds = [];
      draft.isFocused = false;
      draft.isMouseInMenu = false;
      draft.isMouseInClear = false;
      break;
    case "changeSearchValue":
      draft.searchValue = action.val;
      const lowerCaseSearchVal = action.val.toLowerCase();
      draft.filteredOptions = draft.allOptions
        .filter((i) => i.label.toLowerCase().includes(lowerCaseSearchVal))
        .filter((i) => !draft.selectedIds.includes(i.id));
      break;
    case "selectOption":
      draft.selectedIds.push(action.val.id);
      draft.filteredOptions = draft.allOptions.filter(
        (i) => !draft.selectedIds.includes(i.id)
      );
      draft.searchValue = "";
      draft.isFocused = false;
      draft.isMouseInMenu = false;
      break;
    case "unselectOption":
      draft.selectedIds = draft.selectedIds.filter((i) => i !== action.val.id);
      draft.filteredOptions = draft.allOptions.filter(
        (i) => !draft.selectedIds.includes(i.id)
      );
      draft.isFocused = false;
      draft.isMouseInMenu = false;
      break;
  }
});

const init = (options: Immutable<Array<Option>>): State => ({
  allOptions: [...options],
  filteredOptions: [...options],
  isFocused: false,
  isMouseInMenu: false,
  isMouseInClear: false,
  searchValue: "",
  selectedIds: [],
});

export default MultiSelectFilter;
