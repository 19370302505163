/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { Dispatch, useEffect, useState } from "react";

import { Immutable } from "immer";

import Airtable from "airtable";

import { useGoogleApiStatus } from "components/utils/google_api_provider";
import { useAirtableApiStatus } from "components/utils/airtable_api_provider";

import {
  QueryInput,
  GeoResult,
  PlaceResultMap,
  PlaceResultRecord,
  Action,
} from "reducer";

import Loading from "components/utils/loading";

import { stringifyArr } from "./utils";

import NearbyPlacesResultsDisplay from "./display";

import DynamicMarginContainer from "components/utils/container/dynamic_margin";
import Button from "components/utils/button";

const NearbyPlacesResults = ({
  table,
  addressRowId,
  queryInput,
  geoResult,
  placeResults,
  placeResultsTable,
  dispatch,
  saved,
}: {
  table: string;
  addressRowId: string;
  queryInput: Immutable<QueryInput>;
  geoResult: null | Immutable<GeoResult>;
  placeResults: null | Immutable<PlaceResultMap>;
  placeResultsTable: null | Immutable<Array<PlaceResultRecord>>;
  dispatch: Dispatch<Action>;
  saved: boolean;
}) => {
  const th = useTheme<Theme>();

  const [isPushing, setIsPushing] = useState(false);

  const googleApiStatus = useGoogleApiStatus();

  const airtableApiStatus = useAirtableApiStatus();
  const apiKey = airtableApiStatus.key;
  const apiBase = airtableApiStatus.base;

  useEffect(() => {
    if (!saved) {
      googleApiStatus.setRuntimeErr(false);
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: queryInput.address }, (res, st) => {
        if (st !== window.google.maps.GeocoderStatus.OK) {
          googleApiStatus.setRuntimeErr(true);
        } else {
          const firstResult = res[0];
          dispatch({
            type: "geoResult",
            val: {
              address: firstResult.formatted_address,
              placeId: firstResult.place_id,
              gps: {
                lat: firstResult.geometry.location.lat(),
                lng: firstResult.geometry.location.lng(),
              },
            },
          });
        }
      });
    }
  }, [googleApiStatus, dispatch, saved, queryInput]);

  const handlePush = () => {
    if (apiKey && apiBase && placeResultsTable) {
      setIsPushing(true);
      const base = new Airtable({ apiKey: apiKey }).base(apiBase);
      const baseTable = base(table);
      let out = `### ${stringifyArr(queryInput.gaugingTenants)} within ${
        queryInput.searchRadius
      } mi\n[See mapped result](${encodeURI(
        `https://cadence-rho.now.sh/saved/${table}/${addressRowId}`
      )})\n`;
      placeResultsTable.forEach((i) => {
        out = `${out}\nSearch Term **${i.gaugingTenant}** Name **${
          i.name
        }** Location **${i.address}** Miles **${i.distance.toFixed(2)}**`;
      });
      baseTable
        .update([
          {
            id: addressRowId,
            fields: {
              "Proximity Data": out,
              "Baphomap Memories": JSON.stringify({
                v: "1",
                queryInput: queryInput,
                geoResult: geoResult,
                placeResults: placeResults,
              }),
            },
          },
        ])
        .then((_) => {
          setIsPushing(false);
        });
    }
  };

  return (
    <div>
      {queryInput && geoResult && (
        <div
          css={{
            backgroundColor: th.semanticColors.accents[1],
            paddingTop: th.space[8],
            paddingBottom: th.space[10],
            borderTopStyle: "solid",
            borderTopWidth: th.borderWidths[1],
            borderColor: th.semanticColors.accents[2],
          }}
        >
          <DynamicMarginContainer>
            <NearbyPlacesResultsDisplay
              queryInput={queryInput}
              geoResult={geoResult}
              placeResults={placeResults}
              placeResultsTable={placeResultsTable}
              dispatch={dispatch}
              saved={saved}
            />
            {geoResult !== null && placeResultsTable !== null && (
              <div
                css={{
                  gridArea: "bt",
                  marginTop: th.space[11],
                }}
              >
                <div
                  css={{
                    width: th.sizes[10],
                  }}
                >
                  <Button onClickHandler={handlePush} label={"Push To DB"} />
                </div>
                {isPushing && (
                  <div
                    css={{
                      marginLeft: th.space[8],
                      maxWidth: th.sizes[8],
                    }}
                  >
                    <Loading label={"pushing results..."} />
                  </div>
                )}
              </div>
            )}
          </DynamicMarginContainer>
        </div>
      )}
    </div>
  );
};

export default NearbyPlacesResults;
