/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

const Button = ({
  onClickHandler,
  label,
  disabled
}: {
  onClickHandler: () => void;
  label: string;
  disabled?: boolean;
}) => {
  const th = useTheme<Theme>();
  return (
    <button
      onClick={() => onClickHandler()}
      css={{
        ...th.modules.button.base,
        backgroundColor: th.semanticColors.accents[7],
        color: th.semanticColors.background,
        borderStyle: "solid",
        borderRadius: th.radii[3],
        borderWidth: th.borderWidths[3],
        borderColor: th.semanticColors.accents[7],
        paddingTop: th.space[8],
        paddingBottom: th.space[8],
        fontSize: th.fontSizes.smaller[1],
        transition: "all 0.2s ease 0s",
        ":hover": {
          backgroundColor: "inherit",
          color: th.semanticColors.accents[7]
        },
        ":disabled": {
          cursor: "default",
          borderColor: th.semanticColors.accents[3],
          backgroundColor: th.semanticColors.accents[3],
          color: th.semanticColors.accents[1]
        }
      }}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
