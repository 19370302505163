/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { Dispatch } from "react";

import { Immutable } from "immer";

import { State, Action } from "reducer";

import NearbyPlacesGaugingTenantSelect from "./gauging_tenant_select";
import NearbyPlacesRadiusInput from "./radius_input";

import Button from "components/utils/button";

const NearbyPlacesQueryForm = ({
  state,
  dispatch,
}: {
  state: Immutable<State>;
  dispatch: Dispatch<Action>;
}) => {
  const th = useTheme<Theme>();

  const updateQueryInput = () => {
    const address = state.addresses?.selection?.val.label;
    const gaugingTenants = (state.gaugingTenants || []) as Array<string>;
    const searchRadius = state.searchRadius;
    if (address && gaugingTenants.length > 0 && searchRadius) {
      dispatch({
        type: "updateQueryInput",
        val: {
          address: address,
          gaugingTenants: gaugingTenants,
          searchRadius: searchRadius,
        },
      });
    } else {
      throw "address, gauging tenants and search radius must be set to query google maps";
    }
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {state.tables?.selection && state.addresses?.selection && (
        <NearbyPlacesGaugingTenantSelect
          tableOptions={state.tables.selection.gaugingTenantOptions}
          rowOptions={state.addresses.selection.gaugingTenants}
          maxSelection={state.utils.gaugingTenantLabels.length}
          dispatch={dispatch}
        />
      )}
      {state.gaugingTenants && state.gaugingTenants.length > 0 && (
        <NearbyPlacesRadiusInput
          value={state.searchRadius}
          dispatch={dispatch}
        />
      )}
      {state.gaugingTenants && state.gaugingTenants.length > 0 && (
        <div
          css={{
            marginTop: th.space[10],
            maxWidth: th.sizes[10],
          }}
        >
          <Button
            onClickHandler={() => updateQueryInput()}
            label={"Fetch Results"}
            disabled={state.searchRadius === null}
          />
        </div>
      )}
    </div>
  );
};

export default NearbyPlacesQueryForm;
