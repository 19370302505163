/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { Dispatch, useCallback } from "react";

import { Immutable } from "immer";

import Airtable from "airtable";

import { State, Action, TableOption, AddressOption } from "reducer";

import { useAirtableApiStatus } from "components/utils/airtable_api_provider";

import DynamicMarginContainer from "components/utils/container/dynamic_margin";

import TableSelect from "components/utils/airtable/table_select";
import AddressSelect from "components/utils/airtable/address_select";

interface AddressQuery extends Airtable.FieldSet {
  Address: string;
  ["Gauging Tenant"]: string[];
}

const Address = ({
  state,
  dispatch,
}: {
  state: Immutable<State>;
  dispatch: Dispatch<Action>;
}) => {
  const th = useTheme<Theme>();

  const airtableApiStatus = useAirtableApiStatus();
  const apiKey = airtableApiStatus.key;
  const apiBase = airtableApiStatus.base;

  const handleTableRequest = useCallback(
    (resp: Response) => {
      resp.json().then((i) => {
        if (resp.status === 200) {
          const tableOptions: TableOption[] = i.tables.reduce(
            (tot: TableOption[], i: any) => {
              const hasAddress =
                i.fields.find((jj: any) => jj["name"] === "Address") !==
                undefined;
              if (hasAddress) {
                const hasMemories =
                  i.fields.find(
                    (jj: any) => jj["name"] === "Baphomap Memories"
                  ) !== undefined;
                if (hasMemories) {
                  const gaugingTenants = i.fields.find(
                    (jj: any) => jj["name"] === "Gauging Tenant"
                  );
                  if (gaugingTenants) {
                    let gaugingTenantsPrepped = [
                      ...new Set(
                        gaugingTenants.config.options.choices.map(
                          (i: any) => i.name
                        )
                      ),
                    ];
                    gaugingTenantsPrepped.sort();
                    tot.push({
                      val: { id: i.id, label: i.name },
                      gaugingTenantOptions: gaugingTenantsPrepped.map(
                        (i: any) => ({ id: i, label: i })
                      ),
                    });
                  }
                }
              }
              return tot;
            },
            [] as TableOption[]
          );
          dispatch({
            type: "updateTableOptions",
            val: tableOptions,
          });
        }
      });
    },
    [dispatch]
  );

  const handleTableSelection = useCallback(
    (id: string) => {
      dispatch({ type: "selectTable", id: id });
    },
    [dispatch]
  );

  const handleRefreshTableData = useCallback(() => {
    dispatch({ type: "refreshTableData" });
  }, [dispatch]);

  const handleAddressOptionUpdate = useCallback(
    (base: Airtable.Base, table: string) => {
      const baseTable = base(table) as Airtable.Table<AddressQuery>;
      let allRecords: Array<AddressOption> = [];
      baseTable
        .select({
          view: "Grid view",
          fields: ["Address", "Gauging Tenant"],
        })
        .eachPage((records, next) => {
          allRecords = allRecords.concat(
            records
              .filter((i) => i.fields.Address)
              .map((i) => ({
                val: {
                  id: i.id,
                  label: i.fields.Address,
                },
                gaugingTenants: i.fields["Gauging Tenant"] || [],
              }))
          );
          next();
        })
        .then(() => {
          dispatch({ type: "updateAddressOptions", val: allRecords });
        });
    },
    [dispatch]
  );

  const handleAddressSelection = useCallback(
    (id: string) => {
      dispatch({
        type: "selectAddress",
        id: id,
      });
    },
    [dispatch]
  );

  const handleAddressChange = useCallback(() => {
    dispatch({
      type: "deleteAddress",
    });
  }, [dispatch]);

  return (
    <div
      css={{
        width: "100%",
        borderBottomStyle: "solid",
        borderBottomWidth: th.borderWidths[1],
        borderColor: th.semanticColors.accents[2],
        paddingTop: th.space[8],
        paddingBottom: th.space[10],
        backgroundColor: th.semanticColors.accents[0],
      }}
    >
      <DynamicMarginContainer>
        <div
          css={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            css={{
              ...th.modules.text.body,
              fontSize: th.fontSizes.larger[0],
              fontWeight: th.fontWeights.medium,
            }}
          >
            {"Site Selection"}
          </p>
          {apiKey && apiBase && (
            <TableSelect
              options={state.tables?.options.map((i) => i.val) || null}
              selection={state.tables?.selection?.val || null}
              handleTableRequest={handleTableRequest}
              handleTableSelection={handleTableSelection}
              handleRefreshTableData={handleRefreshTableData}
              apiKey={apiKey}
              apiBase={apiBase}
            />
          )}
          {apiKey && apiBase && state.tables?.selection && (
            <AddressSelect
              table={state.tables.selection.val.label}
              addressState={state.addresses}
              tableRefreshCount={state.tables.selectionRefreshCount}
              handleAddressOptionUpdate={handleAddressOptionUpdate}
              handleAddressSelection={handleAddressSelection}
              handleAddressChange={handleAddressChange}
              apiKey={apiKey}
              apiBase={apiBase}
            />
          )}
        </div>
      </DynamicMarginContainer>
    </div>
  );
};

export default Address;
