/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { Immutable } from "immer";

import { CheckCircle } from "react-feather";

import { QueryInput, GeoResult } from "reducer";
import { stringifyArr } from "../../utils";

const NearbyPlacesResultsHeader = ({
  queryInput,
  geoResult,
}: {
  queryInput: Immutable<QueryInput>;
  geoResult: Immutable<GeoResult>;
}) => {
  const th = useTheme<Theme>();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          marginBottom: th.space[4],
        }}
      >
        <p
          css={{
            ...th.modules.text.body,
            fontSize: th.fontSizes.smaller[0],
            marginRight: th.space[8],
          }}
        >
          <span css={{ fontWeight: th.fontWeights.medium }}>
            {stringifyArr(queryInput.gaugingTenants)}
          </span>
          {" within "}
          <span
            css={{ fontWeight: th.fontWeights.medium }}
          >{`${queryInput.searchRadius} mi`}</span>
          {" of "}
          <span css={{ fontWeight: th.fontWeights.medium }}>
            {queryInput.address}
          </span>
        </p>
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: th.space[9],
        }}
      >
        <div
          css={{
            flexDirection: "row",
            alignItems: "center",
            fontSize: th.fontSizes.smaller[5],
            marginRight: th.space[9],
            color: th.semanticColors.success.base,
          }}
        >
          <CheckCircle size={th.fontSizes.larger[4]} />
        </div>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            color: th.semanticColors.accents[7],
            paddingBottom: th.space[1],
          }}
        >
          <p
            css={{
              ...th.modules.text.body,
              fontSize: th.fontSizes.smaller[2],
              fontWeight: th.fontWeights.medium,
              marginRight: th.space[6],
            }}
          >
            {"GPS"}
          </p>
          <p
            css={{
              ...th.modules.text.body,
              fontSize: th.fontSizes.smaller[3],
            }}
          >
            {`${geoResult.gps.lat},${geoResult.gps.lng}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NearbyPlacesResultsHeader;
