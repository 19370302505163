/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import DynamicMarginContainer from "components/utils/container/dynamic_margin";

const ErrSaved = ({ err }: { err: string }) => {
  const th = useTheme<Theme>();

  return (
    <div
      css={{
        backgroundColor: th.semanticColors.accents[1],
        paddingTop: th.space[8],
        paddingBottom: th.space[8],
        borderBottomStyle: "solid",
        borderBottomWidth: th.borderWidths[1],
        borderColor: th.semanticColors.accents[2],
      }}
    >
      <DynamicMarginContainer>
        <div>
          <p
            css={{
              ...th.modules.text.body,
            }}
          >
            {"There's a problem getting data from Airtable"}
          </p>
          <p
            css={{
              ...th.modules.text.body,
              fontSize: th.fontSizes.smaller[1],
              color: th.semanticColors.error.base,
            }}
          >
            {err}
          </p>
        </div>
      </DynamicMarginContainer>
    </div>
  );
};

export default ErrSaved;
