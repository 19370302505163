/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { useState } from "react";

import { useCookies } from "react-cookie";

import { Settings as SettingsIcon } from "react-feather";

import { useGoogleApiStatus } from "components/utils/google_api_provider";
import { useAirtableApiStatus } from "components/utils/airtable_api_provider";

import DynamicMarginContainer from "components/utils/container/dynamic_margin";
import Input from "components/utils/input";
import Button from "components/utils/button";

const Settings = ({ saved }: { saved?: false }) => {
  const th = useTheme<Theme>();
  const [isSelected, setIsSelected] = useState(false);
  const [airtableKeyEdit, setAirtableKeyEdit] = useState<string | null>(null);
  const [airtableBaseEdit, setAirtableBaseEdit] = useState<string | null>(null);
  const [googleKeyEdit, setGoogleKeyEdit] = useState<string | null>(null);

  const googleApiStatus = useGoogleApiStatus();
  const airtableApiStatus = useAirtableApiStatus();

  const [cookies, setCookie] = useCookies();

  const handleSetIsSelected = () => {
    if (isSelected) {
      setAirtableKeyEdit(null);
      setAirtableBaseEdit(null);
      setGoogleKeyEdit(null);
    } else {
      setAirtableKeyEdit(cookies.airtableKey || null);
      setAirtableBaseEdit(cookies.airtableBase || null);
      setGoogleKeyEdit(cookies.googleKey || null);
    }
    setIsSelected(!isSelected);
  };

  const updateKeys = () => {
    setCookie("airtableKey", airtableKeyEdit || "");
    setCookie("airtableBase", airtableBaseEdit || "");
    setCookie("googleKey", googleKeyEdit || "");
    window.location.reload(false);
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        height: th.sizes[4],
        paddingTop: th.space[6],
        paddingBottom: th.space[6],
        borderBottomStyle: "solid",
        borderBottomWidth: th.borderWidths[1],
        borderColor: th.semanticColors.accents[2],
      }}
    >
      <DynamicMarginContainer>
        <div
          css={{
            fontSize: th.fontSizes.smaller[1],
          }}
        >
          {!airtableApiStatus.key ? (
            <p
              css={{
                ...th.modules.text.body,
                color: th.semanticColors.error.base,
              }}
            >
              {"No Airtable API Key; Click Gear To Set"}
            </p>
          ) : !airtableApiStatus.base ? (
            <p
              css={{
                ...th.modules.text.body,
                color: th.semanticColors.error.base,
              }}
            >
              {"No Airtable API Base; Click Gear To Set"}
            </p>
          ) : !googleApiStatus.hasKey ? (
            <p
              css={{
                ...th.modules.text.body,
                color: th.semanticColors.error.base,
              }}
            >
              {"No Google Maps API Key; Click Gear To Set"}
            </p>
          ) : googleApiStatus.authFailure ? (
            <p
              css={{
                ...th.modules.text.body,
                color: th.semanticColors.error.base,
              }}
            >
              {"Invalid Google Maps API Key"}
            </p>
          ) : googleApiStatus.runtimeErr ? (
            <p
              css={{
                ...th.modules.text.body,
                color: th.semanticColors.error.base,
              }}
            >
              {"Google Maps API Issue; Check Console For Details"}
            </p>
          ) : !googleApiStatus.loaded ? (
            <p
              css={{
                ...th.modules.text.body,
                color: th.semanticColors.warning.base,
              }}
            >
              {"Loading Google Maps API..."}
            </p>
          ) : null}
        </div>
      </DynamicMarginContainer>
      <div
        css={{
          position: "absolute",
          right: th.space[8],
        }}
      >
        <div
          css={{
            position: "relative",
          }}
        >
          <div
            onClick={() => handleSetIsSelected()}
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              color: th.semanticColors.accents[5],
              transition: "all 0.2s ease 0s",
              ":hover": {
                color: th.semanticColors.foreground,
              },
            }}
          >
            <SettingsIcon />
          </div>
          {isSelected && (
            <div
              css={{
                position: "absolute",
                top: th.space[11],
                right: 0,
                display: "flex",
                flexDirection: "column",
                ...th.modules.container.popover,
                minWidth: 0,
                width: th.sizes[10],
                padding: th.space[9],
              }}
            >
              <Input
                value={airtableKeyEdit || ""}
                onChangeHandler={setAirtableKeyEdit}
                label={"airtable key"}
              />
              <Input
                value={airtableBaseEdit || ""}
                onChangeHandler={setAirtableBaseEdit}
                label={"airtable base"}
              />
              <Input
                value={googleKeyEdit || ""}
                onChangeHandler={setGoogleKeyEdit}
                label={"google maps key"}
              />
              <div
                css={{
                  marginTop: th.space[4],
                }}
              >
                <Button onClickHandler={updateKeys} label={"Update"} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
