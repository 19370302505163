/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { Theme } from "style/theme";

import { FunctionComponent } from "react";

type FormLabelProps = {
  label: string;
};

const FormLabel: FunctionComponent<FormLabelProps> = ({ label, children }) => {
  const th = useTheme<Theme>();
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p
        css={{
          ...th.modules.text.body,
          fontSize: th.fontSizes.smaller[1],
          paddingBottom: th.space[5],
        }}
      >
        {label}
      </p>
      {children}
    </div>
  );
};

export default FormLabel;
